import { getAllLocalizedVersionsOfRoute, routes } from './index';

/*
 * This list contains:
 * all the routes we WANT bots to index and follow.
 *
 * NOTE: has to be an exact match to allow bots
 * NO TRAILING '/' !!!
 */
const allowNonLocalizedBotIndexFollowList = [
  routes.signIn,
  routes.signUp,
  routes.base,
  routes.menu,
  '/about-popeyes',
  '/contact-us', // main support page & contact us
  routes.storeLocator,
  routes.offers,
  '/company',
  '/our-story',
  '/legal-page',
  '/nutritional-information',
  '/terms-of-service',
  '/accessibility-statement',
  '/sitemap',
  '/corporate-responsibility',
  '/animal-welfare-statement',
  routes.foundation,
  '/privacy-policy',
  '/franchise-international',
  '/franchise',
];

const allowLocalizedBotIndexFollowList = [...getAllLocalizedVersionsOfRoute('exploreMenu')];

export const allowBotIndexFollowList = allowNonLocalizedBotIndexFollowList.concat(
  allowLocalizedBotIndexFollowList
);

/*
 * turns ON the allow list which will apply noindex/nofollow
 * to all routes not inluded in the above list
 *
 */
export const shouldUseAllowBotIndexFollowList = true;
