import { Event as AmplitudeEvent } from '@amplitude/analytics-types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { userHasSignedIn as userHasSignedInOnCognito } from 'remote/auth';
import { initCognitoSession } from 'utils/cognito';
import { RBIEnv, brand, env, isLocalDev, platform } from 'utils/environment';
import { initLaunchDarkly } from 'utils/launchdarkly';

interface GtmEvent {
  event: string;
  brand: string;
  timestamp: number;
  duration: number;
  platform: string;
}

export interface GtmEventBase {
  event: string;
}

declare global {
  interface Window {
    dataLayer: (GtmEvent | (AmplitudeEvent & GtmEventBase))[];
    LOADING_START_TIME: number;
    /**
     * window.Cypress exists when our app is running within Cypress
     * Places we currently check for this:
     * - frontend/src/state/graphql/links/index.ts - avoid BatchHttpLink
     *
     * https://docs.cypress.io/faq/questions/using-cypress-faq.html#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
     */
    Cypress?: any;
    // When run with cypress for cypress-v2 package,
    // we instantiate the LD client with predefined flags
    _initial_cypress_feature_flags?: object;
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    _skipUnavailableItemsIntervalCheck?: boolean;
  }
}

const emitLoadingSuccessEvent = (rbiBrand: string, rbiPlatform: string) => {
  const successTime = performance.now?.();
  const durationTillSuccess = successTime - window.LOADING_START_TIME;
  (window.dataLayer || []).push({
    event: 'APP_BUNDLE_LOADING_SUCCESS',
    brand: rbiBrand,
    timestamp: successTime,
    duration: durationTillSuccess,
    platform: rbiPlatform,
  });
};

export default async function bootstrap(
  renderApp: ({
    flags,
    userHasSignedIn,
    userSession,
  }: {
    flags: Record<string, unknown>;
    userHasSignedIn: boolean;
    userSession: CognitoUserSession | null;
  }) => void
): Promise<void> {
  const [ENV, BRAND, PLATFORM] = [env(), brand().toUpperCase(), platform()];

  if (!isLocalDev) {
    emitLoadingSuccessEvent(BRAND, PLATFORM);
  }

  const [flags, userSession] = await Promise.all([initLaunchDarkly(), initCognitoSession()]);

  // this has to be after init cognito session.
  const userHasSignedIn = await userHasSignedInOnCognito();

  if (ENV === RBIEnv.TEST) {
    const defaultFlags = {};
    renderApp({
      flags: defaultFlags,
      userHasSignedIn,
      userSession,
    });
    return;
  }

  renderApp({
    flags,
    userHasSignedIn,
    userSession,
  });
}
