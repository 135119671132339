import React, { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import {
  LinkWrapper,
  PointsNumber,
  StyledPointsIcon,
  StyledRewardsLogo,
} from './loyalty-app-header.styled';

export const LoyaltyAppHeaderView: FC<ILoyaltyAppHeaderProps> = ({ points, to }) => {
  const { formatMessage } = useIntl();

  return (
    <LinkWrapper to={to}>
      <VisuallyHidden>{formatMessage({ id: 'rewardsPointsAmount' }, { points })}</VisuallyHidden>
      <StyledRewardsLogo />
      <StyledPointsIcon />
      <PointsNumber aria-hidden>{points}</PointsNumber>
    </LinkWrapper>
  );
};
