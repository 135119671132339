import { differenceInYears, parse } from 'date-fns';
import { isNil } from 'lodash-es';

import { NonNullableObject } from '@rbi-ctg/frontend';

import { Identify, identify } from './amplitude-package';
import { IAmplitudeAttributes } from './types';

export function sanitizeValues<M extends object = IAmplitudeAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

const getAge = (dob: string): number | null => {
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return null;
};

/**
 *
 * @param userAttributes object with any values (we'll only take the ones we care abour)
 * @returns
 */
export function extractAmplitudeUserAttributes(
  userAttributes: Record<string, string | string>
): IAmplitudeAttributes {
  const age = getAge(userAttributes.dob);
  return {
    ...(age && { $Age: age }),
    ...(userAttributes.brand && { brand: userAttributes.brand }),
    ...(userAttributes.region && { region: userAttributes.region }),
    ...(userAttributes.env && { env: userAttributes.env }),
    ...(userAttributes['Date of Birth'] && { 'Date of Birth': userAttributes['Date of Birth'] }),
    ...(userAttributes['Email Opt In'] && { 'Email Opt In': userAttributes['Email Opt In'] }),
    ...(userAttributes.favoriteStores && { favoriteStores: userAttributes.favoriteStores }),
    ...(userAttributes['Join Date'] && { 'Join Date': userAttributes['Join Date'] }),
    ...(userAttributes.language && { language: userAttributes.language }),
    ...(userAttributes.Locale && { Locale: userAttributes.Locale }),
    ...(userAttributes['Legacy User'] && { 'Legacy User': userAttributes['Legacy User'] }),
    ...(userAttributes['Location Services'] && {
      'Location Services': userAttributes['Location Services'],
    }),
    ...(userAttributes.marketingEmail && { marketingEmail: userAttributes.marketingEmail }),
    ...(userAttributes.marketingPush && { marketingPush: userAttributes.marketingPush }),
    ...(userAttributes.orderStatus && { orderStatus: userAttributes.orderStatus }),
    ...(userAttributes.platform && { Platform: userAttributes.platform }),
    ...(userAttributes['RBI Cognito ID'] && { 'RBI Cognito ID': userAttributes['RBI Cognito ID'] }),
    ...(userAttributes.rewardsEmail && { rewardsEmail: userAttributes.rewardsEmail }),
    ...(userAttributes.rewardsPush && { rewardsPush: userAttributes.rewardsPush }),
    ...(userAttributes.Timezone && { Timezone: userAttributes.Timezone }),
    ...(userAttributes['Type Preference'] && {
      'Type Preference': userAttributes['Type Preference'],
    }),
    ...(userAttributes['Snack Preference'] && {
      'Snack Preference': userAttributes['Snack Preference'],
    }),
    ...(userAttributes['Size Preference'] && {
      'Size Preference': userAttributes['Size Preference'],
    }),
    ...(userAttributes['Time Preference'] && {
      'Time Preference': userAttributes['Time Preference'],
    }),
    ...(userAttributes['IOS Location Permissions'] && {
      'IOS Location Permissions': userAttributes['IOS Location Permissions'],
    }),
    ...(userAttributes['Android Location Permissions'] && {
      'Android Location Permissions': userAttributes['Android Location Permissions'],
    }),
  };
}

export const setAmplitudeUserAttributes = (userAttributes: IAmplitudeAttributes) => {
  const identifyObj = new Identify();
  for (const userAttributeKey of Object.keys(userAttributes)) {
    identifyObj.set(userAttributeKey, userAttributes[userAttributeKey]);
  }
  identify(identifyObj);
};
