import { useEffect } from 'react';

import { IUseAlertOrderLimit } from './types';

const useAlertOrderLimit = ({
  calculateCartTotal,
  alertOrderLimit,
  checkoutPriceLimit,
  setCartPriceLimitExceeded,
  isCatering,
  checkoutCateringPriceLimit = 0,
}: IUseAlertOrderLimit) => {
  useEffect(() => {
    const limit = isCatering ? checkoutCateringPriceLimit : checkoutPriceLimit;
    const priceLimitExceeded = calculateCartTotal() > limit;

    if (priceLimitExceeded) {
      alertOrderLimit();
    }

    setCartPriceLimitExceeded(priceLimitExceeded);
  }, [
    calculateCartTotal,
    alertOrderLimit,
    checkoutPriceLimit,
    isCatering,
    checkoutCateringPriceLimit,
    setCartPriceLimitExceeded,
  ]);
};

export default useAlertOrderLimit;
