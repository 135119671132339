/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { LANGUAGES, REGIONS } from './types';

export enum PROD_SUPPORTED_LANGUAGES {
  ca = LANGUAGES.ca,
  de = LANGUAGES.de,
  en = LANGUAGES.en,
  es = LANGUAGES.es,
  eu = LANGUAGES.eu,
  fr = LANGUAGES.fr,
  gl = LANGUAGES.gl,
  it = LANGUAGES.it,
  ko = LANGUAGES.ko,
}

export const PROD_DEFAULT_LANGUAGE = {
  [REGIONS.CA]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.CH]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.US]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.KR]: PROD_SUPPORTED_LANGUAGES.ko,
  [REGIONS.ES]: PROD_SUPPORTED_LANGUAGES.es,
  [REGIONS.FR]: PROD_SUPPORTED_LANGUAGES.fr,
};
