import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

interface ILimitMessageProps {
  limit: number;
}

const MutedText = styled.div`
  text-align: center;
  color: ${Styles.color.grey.one};
  font-size: 0.8rem;
`;

const LimitMessage: React.FC<ILimitMessageProps> = ({ limit }) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const { isCatering, checkoutCateringPriceLimit } = useOrderContext();

  return (
    <MutedText>
      {formatMessage(
        { id: 'orderLimitWarningX' },
        {
          limit: formatCurrencyForLocale(isCatering ? checkoutCateringPriceLimit : limit),
        }
      )}
    </MutedText>
  );
};

export default LimitMessage;
