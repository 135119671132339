/**
 * mParticle Provider RN Implementation
 * We will uncomment logic as we continue to implement various portions and swap in RN functionality.
 */

//
// TODO refactor this to NOT be a provider and be static and other cleanup
//
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DestinationPlugin, Plugin, PluginType } from '@amplitude/analytics-types';
import { AllowedEvent } from '@rbilabs/mparticle-client';

import { setBrazeUserAttributes } from 'state/braze/set-braze-user-attribute';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import {
  determinePlatformFromNavigator,
  normalizeBooleans,
  sanitizeValues,
} from 'state/mParticle/utils';
import AuthStorage from 'utils/cognito/storage';
import { addContext as addLoggerContext } from 'utils/datadog';
import { brand, env, getApiKey, isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';
import { initGoogleTagManager, sendGoogleTagManagerEvent } from 'utils/google-tag-manager';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import noop from 'utils/noop';

import {
  Revenue,
  Types,
  add,
  getDeviceId,
  getSessionId,
  init,
  revenue,
  setSessionId as setAmplitudeSessionId,
  setUserId,
  track,
} from './amplitude-package';
import { ValidPropertyType } from './types';
import { extractAmplitudeUserAttributes, setAmplitudeUserAttributes } from './utils';

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

export const staticSessionId = { current: 'UN-INITIALIZED' };

interface ILogAmplitudeCustomEventProps {
  name: AllowedEvent['name'];
  attributes: Record<string, string>;
}
interface IAmplitudeContext {
  logAmplitudeRevenueEvent: (props: {
    totalAmount: number;
    eventProperties: Record<string, string | number | boolean>;
  }) => void;
  initialize: () => void;
  updateUserLocationPermissionStatus: () => Promise<void>;
  setAmplitudeUserId: (userId?: string | null) => void;
  unsetAmplitudeUserId: () => void;
  logAmplitudeCustomEvent: (event: ILogAmplitudeCustomEventProps) => void;
  sessionId: string;
  deviceId: string;
  updateAmplitudeUserAttributes: (userAttributes: Record<string, any>) => void;
  addTrackerPlugin: (plugin: DestinationPlugin) => void;
}

const AmplitudeContext = React.createContext<IAmplitudeContext>({
  initialize: noop,
  updateUserLocationPermissionStatus: () => Promise.resolve(),
  logAmplitudeCustomEvent: () => noop,
  logAmplitudeRevenueEvent: () => noop,
  setAmplitudeUserId: () => noop,
  unsetAmplitudeUserId: () => noop,
  sessionId: 'fake-id',
  deviceId: 'fake-id',
  updateAmplitudeUserAttributes: noop,
  addTrackerPlugin: () => noop,
});

export const useAmplitudeContext = () => useContext<IAmplitudeContext>(AmplitudeContext);

/**
 * - Sets the session ID.
 * - Sets the user ID.
 * - Sets the device ID.
 * - Exposes logging and initialize methods.
 */
export function AmplitudeProvider(props: { children: ReactNode }) {
  const [sessionId, setSessionId] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const { region } = useLocale();
  const enableOptionalAmplitudeTracking = useFlag(
    LaunchDarklyFlag.ENABLE_OPTIONAL_AMPLITUDE_TRACKING
  );
  const amplitudeKey = getApiKey('amplitude');
  const { updateUserDeviceId: updateLaunchDarklyDeviceId } = useLDContext();

  /**
   * We never log PII to Amplitude.
   * Though you may pass any object to this method,
   * `extractAmplitudeUserAttributes` will only add the attributes we want.
   */
  const updateAmplitudeUserAttributes = useCallback(
    async (userAttributes: Record<string, any> = {}) => {
      const cognitoId = AuthStorage.getItem(StorageKeys.USER)?.cognitoId;

      if (!cognitoId) {
        return;
      }

      // Ensures user ID is set
      setAmplitudeUserId(cognitoId);

      const globalUserAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        platform: determinePlatformFromNavigator(),
      };

      const sanitizedAttributes = sanitizeValues({ ...userAttributes, ...globalUserAttributes });
      const normalizedAttributes = normalizeBooleans(sanitizedAttributes);

      const amplitudeUserAttributes = extractAmplitudeUserAttributes(normalizedAttributes);
      setAmplitudeUserAttributes(amplitudeUserAttributes);

      // set Braze user attributes
      setBrazeUserAttributes(normalizedAttributes);
    },
    [region]
  );

  /**
   * Initialize the Amplitude client.
   * If a user is already logged in, we will initialize with the user's cognito ID.
   */
  const initialize = useCallback(
    (optOut = false) => {
      const cognitoId = AuthStorage.getItem(StorageKeys.USER)?.cognitoId;

      if (!enableOptionalAmplitudeTracking) {
        // If we decide that we do not want to disable events,
        // when we disable the flag (making Amplitude tracking not-optional),
        optOut = false;
      }

      /**
       * https://amplitude.github.io/Amplitude-TypeScript/modules/_amplitude_analytics_browser.html#init
       *
       * If tracking Start Session and End Session events is critical to your analysis outside of session lengths,
       * you can easily turn on tracking for these events by adding this line of code before initializing the SDK.
       * https://help.amplitude.com/hc/en-us/articles/115002323627-Tracking-Sessions
       */
      const options: Types.BrowserOptions = {
        defaultTracking: {
          sessions: true,
        },
        optOut,
      };

      if (cognitoId) {
        init(amplitudeKey, cognitoId, options);

        // This will automatically add global user attributes.
        updateAmplitudeUserAttributes();
      } else {
        init(amplitudeKey, undefined, options);
      }
      const gtmPlugin: DestinationPlugin = {
        name: 'google-tag-manager',
        type: PluginType.DESTINATION as const,
        setup: initGoogleTagManager,
        execute: sendGoogleTagManagerEvent,
      };
      addTrackerPlugin(gtmPlugin);
    },
    [amplitudeKey, enableOptionalAmplitudeTracking, updateAmplitudeUserAttributes]
  );

  const addTrackerPlugin = useCallback((plugin: Plugin) => add(plugin), []);

  /**
   * @deprecated
   * Logs a custom event to Amplitude.
   * Please use the logRBIEvent method from useCRMEventsContext instead of this.
   * Note - this is used inside of the logRBIEvent in CRMEventsContext.
   */
  const logAmplitudeCustomEvent = useCallback((event: ILogAmplitudeCustomEventProps) => {
    try {
      track(event.name, event.attributes);
    } catch (err) {
      logger.error('Error logging event to Amplitude');
    }
  }, []);

  const logAmplitudeRevenueEvent = useCallback(
    ({
      totalAmount,
      eventProperties,
    }: {
      totalAmount: number;
      eventProperties: {
        [key: string]: ValidPropertyType;
      };
    }) => {
      const revenueEvent = new Revenue();
      revenueEvent.setRevenue(totalAmount).setEventProperties(eventProperties);
      revenue(revenueEvent);
    },
    []
  );

  /**
   * Sets the session ID in State.
   */
  const configureSessionId = useCallback(() => {
    // TODO re-think this function. having iOS throw an error ~100% of the time doesn't make sense.
    const MISSING_SESSION_ERROR_MESSAGE = 'No Amplitude session ID available';
    try {
      const amplitudeSessionId = getSessionId()?.toString();
      if (!amplitudeSessionId) {
        throw new Error(MISSING_SESSION_ERROR_MESSAGE);
      }
      setSessionId(amplitudeSessionId);
      addLoggerContext('session', amplitudeSessionId);
      staticSessionId.current = amplitudeSessionId;
    } catch (error) {
      /**
       * This happens when the web does not have a sessionId yet for iOS to use.
       * We won't bother making it an error since there is nothing for us to do.
       */

      /**
       * Setting a custom session ID. Note, the session ID does note have to be unique across users.
       * https://www.docs.developers.amplitude.com/data/sdks/typescript-react-native/#custom-user-id
       * https://help.amplitude.com/hc/en-us/articles/115002323627-Track-sessions-in-Amplitude#:~:text=The%20session%20ID%20does%20not,you%20use%20to%20group%20sessions.
       */
      const newSessionID = Date.now();
      setAmplitudeSessionId(newSessionID);
      setSessionId(String(newSessionID));
      addLoggerContext('session', String(newSessionID));
      staticSessionId.current = String(newSessionID);

      let errorMessage = MISSING_SESSION_ERROR_MESSAGE;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      // this happens 100% of the time on IOS so it doesn't need to log at a high level
      if (errorMessage.includes(MISSING_SESSION_ERROR_MESSAGE)) {
        logger.debug(errorMessage);
        return;
      }
      logger.error({
        message: `Failed to get Amplitude SessionID: ${String(error)}`,
      });
    }
  }, []);

  const updateDeviceId = async () => {
    const id = getDeviceId();
    if (id) {
      setDeviceId(id);
      // setAmplitudeDeviceId(id?.toLocaleLowerCase()); // toLower keeps this consistent with how it was done from mparticle for continuity of tracking
      updateLaunchDarklyDeviceId(id);
    }
  };

  const setAmplitudeUserId = useCallback<IAmplitudeContext['setAmplitudeUserId']>(userId => {
    if (userId) {
      setUserId(userId);
    }
  }, []);

  const unsetAmplitudeUserId = useCallback<IAmplitudeContext['unsetAmplitudeUserId']>(() => {
    setUserId('');
  }, []);

  const updateUserLocationPermissionStatus = useCallback(async () => {
    const status = await getNativeLocationPermissions();
    if (!status) {
      return;
    }
    if (isNativeIOS()) {
      updateAmplitudeUserAttributes({ 'IOS Location Permissions': status });
    } else {
      updateAmplitudeUserAttributes({ 'Android Location Permissions': status });
    }
  }, [updateAmplitudeUserAttributes]);

  useEffect(() => {
    initialize();
    configureSessionId();
    updateDeviceId().catch(() => {
      logger.error('Error logging event to Amplitude');
    });
  }, []);

  const amplitudeContext = useMemo<IAmplitudeContext>(
    () => ({
      initialize,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      deviceId,
      updateUserLocationPermissionStatus,
      updateAmplitudeUserAttributes,
      setAmplitudeUserId,
      unsetAmplitudeUserId,
      addTrackerPlugin,
    }),
    [
      initialize,
      updateUserLocationPermissionStatus,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      deviceId,
      updateAmplitudeUserAttributes,
      setAmplitudeUserId,
      unsetAmplitudeUserId,
      addTrackerPlugin,
    ]
  );

  return (
    <AmplitudeContext.Provider value={amplitudeContext}>{props.children}</AmplitudeContext.Provider>
  );
}

export default AmplitudeContext.Consumer;
