import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { ICartEntry, IOffer } from '@rbi-ctg/menu';
import { CustomEventNames, EventTypes } from 'state/mParticle';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { findOfferByUniqId } from 'utils/offers';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';
import { toast } from 'utils/toast';

import { ClearSelectedOffer, ISelectedOfferPersistedState, IUseSelectedOfferProps } from './types';

const getPreloadedState = () =>
  LocalStorage.getItem<ISelectedOfferPersistedState>(StorageKeys.OFFER) || {};

export default function useSelectedOffer({
  decorateLogger,
  mParticle,
  offers,
  lockedOffers,
  cartPromoCodeOffers,
  serviceMode,
}: IUseSelectedOfferProps) {
  const {
    selectedOffer: preloadedSelectedOffer = null,
    selectedOfferCartEntry: preloadedSelectedOfferCartEntry = null,
    selectedOfferPrice: preloadedSelectedOfferPrice = 0,
  } = useMemo(getPreloadedState, []);
  const [selectedOffer, setSelectedOffer] = useState<IOffer | null>(preloadedSelectedOffer);
  const [selectedOfferCartEntry, setSelectedOfferCartEntry] = useState<ICartEntry | null>(
    preloadedSelectedOfferCartEntry
  );
  const [selectedOfferPrice, setSelectedOfferPrice] = useState<number>(preloadedSelectedOfferPrice);
  const { logEvent, logAddOrRemoveFromCart } = mParticle;
  const { formatMessage } = useIntl();

  const sanityId = selectedOffer?._id;
  const shortCode = selectedOffer?.shortCode;
  const offerPrice = selectedOffer?.offerPrice ? selectedOffer?.offerPrice / 100 : 0.0;
  const offerName = getFirstStringInLocaleBlockContent(selectedOffer?.name);

  const logOfferRemovedEvents = useCallback(() => {
    logEvent(CustomEventNames.OFFER_SELECTED_CLEARED, EventTypes.Other, {
      'Sanity ID': sanityId,
      'Short Code': shortCode,
      'Offer Price': offerPrice,
      'Offer Name': offerName,
    });
  }, [logEvent, offerName, offerPrice, sanityId, shortCode]);

  const logOfferAddedEvents = useCallback(
    (entry: ICartEntry, currentCartEntries: ICartEntry[]) => {
      mParticle.logEvent(CustomEventNames.OFFER_ADDED, EventTypes.Other, {
        'Sanity ID': sanityId,
        'Short Code': shortCode,
        'Offer Price': offerPrice,
        'Offer Name': offerName,
        Status: 'Success',
      });
      logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry: entry,
        previousCartEntries: currentCartEntries,
      });
    },
    [mParticle, logAddOrRemoveFromCart, offerName, offerPrice, sanityId, shortCode]
  );

  const setSelectedOfferCartEntryWithLogging = useCallback(
    (entry: ICartEntry | null, currentCartEntries: ICartEntry[]) => {
      setSelectedOfferCartEntry(entry);

      if (entry && serviceMode) {
        logOfferAddedEvents(entry, currentCartEntries);
      }
    },
    [logOfferAddedEvents, serviceMode]
  );

  const clearSelectedOffer: ClearSelectedOffer = useCallback(
    (args = { doLog: true, popToast: false }) => {
      const { doLog, popToast } = args;

      const prevOfferCartEntry = selectedOfferCartEntry;

      setSelectedOffer(null);
      setSelectedOfferCartEntry(null);
      setSelectedOfferPrice(0);
      if (popToast) {
        toast.success(formatMessage({ id: 'offerAppliedSuccess' }));
      }

      if (prevOfferCartEntry && doLog) {
        logOfferRemovedEvents();
      }
    },
    [formatMessage, logOfferRemovedEvents]
  );

  const selectOfferById = useCallback(
    (couponId: string) => {
      // Check first if the offer is a locked offer
      const lockedOffer = findOfferByUniqId(couponId, lockedOffers);
      if (lockedOffer) {
        return setSelectedOffer(lockedOffer);
      }
      // Check for cart promo code offers, not included in main offers list
      const cartPromoCodeOffer = findOfferByUniqId(couponId, cartPromoCodeOffers);
      if (cartPromoCodeOffer) {
        return setSelectedOffer(cartPromoCodeOffer);
      }
      // If not a locked offer, check standard offers and promo code offers
      const offer = findOfferByUniqId(couponId, offers);
      if (offer) {
        return setSelectedOffer(offer);
      }
    },
    [lockedOffers, cartPromoCodeOffers, offers]
  );

  const isSelectedOfferCartEntry = useCallback(
    (cartEntry?: ICartEntry | null): boolean =>
      cartEntry?.cartId === selectedOfferCartEntry?.cartId,
    [selectedOfferCartEntry]
  );

  useEffect(() => {
    LocalStorage.setItem(StorageKeys.OFFER, {
      selectedOffer,
      selectedOfferCartEntry,
      selectedOfferPrice,
    });
    decorateLogger({
      selectedOffer,
      selectedOfferCartEntry,
      selectedOfferPrice,
    });
  }, [selectedOffer, selectedOfferCartEntry, selectedOfferPrice]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setSelectedOfferCartEntry: setSelectedOfferCartEntryWithLogging,
    setSelectedOfferPrice,
  };
}
