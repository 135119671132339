import { useUserGeoQuery } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { INDIA_COUNTRY_CODE, POPEYES_INDIA } from './constants';

const useRegionRedirect = () => {
  const enableAutoLocale = useFlag(LaunchDarklyFlag.ENABLE_AUTO_LOCALE);
  const { data } = useUserGeoQuery();

  // We only want to redirect once per session
  const previouslyRedirected = Boolean(LocalStorage.getItem(StorageKeys.REGION_REDIRECT));

  if (!previouslyRedirected && enableAutoLocale) {
    switch (data?.userGeo.country) {
      case INDIA_COUNTRY_CODE:
        LocalStorage.setItem(StorageKeys.REGION_REDIRECT, INDIA_COUNTRY_CODE);
        window.location.href = POPEYES_INDIA;
        return;
      default:
        return;
    }
  }
};

export default useRegionRedirect;
