import { LOCALES } from './types';

export const SORTED_PROD_SUPPORTED_LOCALES = [
  LOCALES.ca_es,
  LOCALES.de_at,
  LOCALES.en_at,
  LOCALES.cs_cz,
  LOCALES.en_cz,
  LOCALES.de_ch,
  LOCALES.en_ca,
  LOCALES.en_ch,
  LOCALES.en_es,
  LOCALES.es_es,
  LOCALES.en_kr,
  LOCALES.en_pt,
  LOCALES.en_us,
  LOCALES.fr_ca,
  LOCALES.fr_fr,
  LOCALES.ko_kr,
  LOCALES.pt_pt,
  LOCALES.pl_pl,
  LOCALES.en_pl,
  LOCALES.ro_ro,
  LOCALES.en_ro,
  LOCALES.gl_es,
  LOCALES.eu_es,
];

export * from './generated/supported-locales.plk';
