import { useCallback, useState } from 'react';

import { Gender } from '@rbilabs/common';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { cloneDeepWith, isPlainObject, pickBy } from 'lodash-es';
import { useIntl } from 'react-intl';
import uuid from 'uuid';

import { DeepPartial } from '@rbi-ctg/frontend';
import {
  useCreateOtpMutation,
  useSignInJwtMutation,
  useSignUpMutation,
  useValidateAuthJwtMutation,
  useValidateOtpMutation,
} from 'generated/rbi-graphql';
import { ModalCb } from 'hooks/use-error-modal';
import { useOtpFeature } from 'hooks/use-otp-feature';
import {
  signOut as cognitoSignOut,
  signUp as cognitoSignUp,
  validateLogin as cognitoValidateLogin,
} from 'remote/auth/cognito';
import { useFlag, useLDContext } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useMParticleContext } from 'state/mParticle';
import { SignInPhases } from 'state/mParticle/constants';
import { platform, welcomeEmailDomain } from 'utils/environment';
import { parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLErrorCodes } from 'utils/errors/types';
import { ISOs } from 'utils/form/constants';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { OTPAuthDeliveryMethod } from 'utils/otp';
import { routes } from 'utils/routing';

import { isOTPEmailEnabled, isOTPSMSEnabled } from '../../../utils/otp/is-flag-enabled';
import { SIGN_IN_FAIL } from '../constants';
import { JwtValidationError, OtpValidationError, UserNotFoundError } from '../errors';

import { useThirdPartyAuthentication } from './use-third-party-authentication';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.HAS_SHOWN_LOCALE_SELECTOR,
  StorageKeys.LAST_TIME_COOKIES_ACCEPTED,
  StorageKeys.TABLE_NUMBER,
];

interface IErrorExtension {
  code?: string;
  statusCode?: number;
  statusCategory?: string;
}

export interface IUseAccountAuthentication {
  refreshCurrentUser(): Promise<void>;
  openErrorDialog: ModalCb;
  setCurrentUser(session: null | CognitoUserSession): void;
}

interface INavigateOptions {
  state: {
    email: string;
  };
}

interface ISignInNavigation {
  navigateOnSuccess?: boolean;
  navigateState?: INavigateOptions;
}

interface ISignInUserParams {
  email?: string;
  phoneNumber?: string;
}

export type ISignIn = ISignInNavigation & ISignInUserParams;

interface ISignUp {
  email: string;
  name: string;
  dob?: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  zipcode?: string;
  gender?: Gender;
}

export interface ISignUpResult {
  jwt: string | null | undefined;
}

interface IValidateLogin {
  jwt: string;
  username: string;
}

interface IGetSesionIdAndChallengeCodeOtp {
  email: string;
  otpCode: string;
  sessionId: string;
}

type IStoreOtpCredentials = { sessionId: string } & ISignInUserParams;

export const getStoredOtpCredentials = () => LocalStorage.getItem(StorageKeys.OTP);
export const storeOtpCredentials = (data: IStoreOtpCredentials) =>
  LocalStorage.setItem(StorageKeys.OTP, data);

export const useAccountAuthentication = ({
  refreshCurrentUser,
  openErrorDialog,
  setCurrentUser,
}: IUseAccountAuthentication) => {
  const { formatMessage } = useIntl();
  const { attemptGetUpdatedLdFlag } = useLDContext();
  const {
    signInEvent: mParticleSignInEvent,
    signOutEvent: mParticleSignOutEvent,
    signUpEvent: mParticleSignUpEvent,
  } = useMParticleContext();
  const { logUserOutOfThirdPartyServices } = useThirdPartyAuthentication();
  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const enableUserNotFoundMaskAuthFlow = useFlag(
    LaunchDarklyFlag.ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW
  );
  const { enableOtpFlagValue } = useOtpFeature();
  const preloaded = LocalStorage.getItem(StorageKeys.AUTH_REDIRECT) || {};
  const [originLocation, setOriginLoc] = useState<null | string>(preloaded.callbackUrl || null);
  const {
    navigate,
    location: { search },
  } = useLocationContext();
  // Track sign in otp method separatly from `enableOtpFlagValue` so that changes
  // in `enableOtpFlagValue` does not affect the sign in method used.
  const [signInOtpMethod, setSignInOtpMethod] = useState<OTPAuthDeliveryMethod>(enableOtpFlagValue);

  const [signInJwtMutation, { loading: signInMutationLoading }] = useSignInJwtMutation();
  const [
    validateAuthJwtMutation,
    { loading: validateAuthMutationLoading },
  ] = useValidateAuthJwtMutation();
  const [createOtpMutation, { loading: createOtpMutationLoading }] = useCreateOtpMutation();
  const [validateOtpMutation, { loading: validateOtpMutationLoading }] = useValidateOtpMutation();
  const [signUpMutation] = useSignUpMutation();

  const getSessionIdAndChallengeCodeOtp = useCallback(
    async ({ email, otpCode, sessionId }: IGetSesionIdAndChallengeCodeOtp) => {
      const { data } = await validateOtpMutation({
        variables: {
          input: {
            code: otpCode,
            email,
            sessionId,
          },
        },
      });

      const { sessionId: validatedSessionId, challengeCode } =
        data?.exchangeOTPCodeForCognitoCredentials ?? {};

      if (!validatedSessionId || !challengeCode) {
        throw new OtpValidationError('GraphQL validation failed');
      }

      return { sessionId: validatedSessionId, code: challengeCode };
    },
    [validateOtpMutation]
  );

  const getSessionIdAndChallengeCode = useCallback(
    async (jwt: string) => {
      try {
        const { data } = await validateAuthJwtMutation({
          variables: {
            input: { jwt },
          },
        });
        const { sessionId, challengeCode } = data?.validateAuthJwt ?? {};
        if (!sessionId || !challengeCode) {
          throw new JwtValidationError();
        }

        return { sessionId, code: challengeCode };
      } catch (error) {
        if (error?.originalError?.[0]?.message?.toLowerCase() === 'email not registered') {
          throw new UserNotFoundError();
        }
        throw error;
      }
    },
    [validateAuthJwtMutation]
  );

  const signOut = useCallback(async () => {
    try {
      await cognitoSignOut();

      const user = LocalStorage?.getItem(StorageKeys.USER)?.cognitoId;
      if (user) {
        // Once we have namespaced auth storage for all platforms we can remove the excluded
        // keys from here but for now we need to make sure we don't wipe all LocalStorage
        LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });

        setCurrentUser(null);

        mParticleSignOutEvent(true);
        logUserOutOfThirdPartyServices();
      }
    } catch (error) {
      mParticleSignOutEvent(false, error.message);
      refreshCurrentUser();
      throw error;
    }
  }, [setCurrentUser, mParticleSignOutEvent, logUserOutOfThirdPartyServices, refreshCurrentUser]);

  const signInWithOtp = async (param: { email: string } | { phoneNumber: string }) => {
    const sessionId = uuid();
    await createOtpMutation({
      variables: {
        input: {
          ...param,
          platform: platform(),
          sessionId,
        },
      },
    });
    storeOtpCredentials({ ...param, sessionId });
    return (navigateState?: INavigateOptions) => {
      navigate(routes.confirmOtp + search, navigateState);
    };
  };

  const signInWithJwt = async (email: string) => {
    LocalStorage.setItem(StorageKeys.LOGIN, email);
    await signInJwtMutation({
      variables: {
        input: {
          email,
          stage: welcomeEmailDomain(),
          platform: platform(),
        },
      },
    });
    return (navigateState?: INavigateOptions) => {
      navigate(routes.authChallengeJwt, navigateState);
    };
  };

  const getOtpFlagValueForAttributes = useCallback(
    async (ldAttributes: DeepPartial<LDUser>) => {
      // omit nested empty/null values
      const attributes = cloneDeepWith(ldAttributes, value =>
        isPlainObject(value) ? pickBy(value, v => !!v) : !!value
      );

      const flagValue = await attemptGetUpdatedLdFlag(
        LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD,
        attributes,
        OTPAuthDeliveryMethod.None
      );
      return flagValue;
    },
    [attemptGetUpdatedLdFlag]
  );

  const signInUsingEnabledMethod = async ({
    email,
    phoneNumber,
    otpMethod,
  }: Pick<ISignIn, 'email' | 'phoneNumber'> & { otpMethod: OTPAuthDeliveryMethod }) => {
    const isEmailEnabled = isOTPEmailEnabled(otpMethod);
    const isSMSEnabled = isOTPSMSEnabled(otpMethod);

    if (isSMSEnabled && phoneNumber) {
      return signInWithOtp({ phoneNumber });
    }

    if (!email) {
      throw new Error('No email provided and SMS login disabled');
    }

    if (isEmailEnabled) {
      return signInWithOtp({ email });
    }

    return signInWithJwt(email);
  };

  const signIn = async ({
    email,
    phoneNumber,
    navigateOnSuccess = true,
    navigateState,
  }: ISignIn) => {
    const otpMethod: OTPAuthDeliveryMethod = await getOtpFlagValueForAttributes({
      email,
      custom: { phoneNumber },
    });
    setSignInOtpMethod(otpMethod);
    try {
      const redirect = await signInUsingEnabledMethod({ email, phoneNumber, otpMethod });
      mParticleSignInEvent({
        phase: SignInPhases.START,
        success: true,
        otpMethod,
      });
      if (navigateOnSuccess) {
        redirect(navigateState);
      }
    } catch (error) {
      const createOtpError = enableUserNotFoundMaskAuthFlow
        ? GraphQLErrorCodes.CREATE_OTP_FAILED
        : GraphQLErrorCodes.AUTH_EMAIL_NOT_REGISTERED;
      const notRegisteredError = parseGraphQLErrorCodes(error)
        .map(err => err.errorCode)
        .includes(createOtpError);
      if (!notRegisteredError) {
        mParticleSignInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          otpMethod,
        });
      }
      error.code = SIGN_IN_FAIL;
      throw error;
    }
  };

  const signUp = async (
    { email, name, dob, phoneNumber, country, wantsPromotionalEmails, zipcode, gender }: ISignUp,
    signInOverride: (args: ISignIn) => Promise<void> = signIn
  ): Promise<ISignUpResult> => {
    let jwt;
    try {
      if (enableSignUpInBE) {
        const { data } = await signUpMutation({
          variables: {
            input: {
              country: country as ISOs,
              dob,
              name,
              phoneNumber,
              platform: platform(),
              stage: welcomeEmailDomain(),
              userName: email,
              wantsPromotionalEmails,
              zipcode,
              gender,
            },
          },
        });
        jwt = data?.signUp;
      } else {
        await cognitoSignUp({
          name,
          phoneNumber,
          username: email,
          country,
          wantsPromotionalEmails,
          dob,
          gender,
        });
      }
    } catch (error) {
      mParticleSignUpEvent({ success: false, message: error?.message });

      throw error;
    }

    mParticleSignUpEvent({ success: true });

    await signInOverride({
      email,
      phoneNumber,
    });

    return { jwt };
  };

  const validateLoginOtp = useCallback(
    async ({ otpCode }) => {
      try {
        const { email, phoneNumber, sessionId: storedSessionId } = getStoredOtpCredentials() ?? {};
        if (!email || !storedSessionId) {
          throw new OtpValidationError('Missing email or sessionId');
        }

        const { code, sessionId } = await getSessionIdAndChallengeCodeOtp({
          email,
          otpCode,
          sessionId: storedSessionId,
        });

        const validateOtpMethod =
          signInOtpMethod && signInOtpMethod !== OTPAuthDeliveryMethod.None
            ? signInOtpMethod
            : await getOtpFlagValueForAttributes({
                email,
                custom: { phoneNumber },
              });
        const session = await cognitoValidateLogin({ username: email, code, sessionId });
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          otpMethod: validateOtpMethod,
        });
        setCurrentUser(session);
      } catch (error) {
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
          otpMethod: signInOtpMethod,
        });

        throw error;
      }
    },
    [
      signInOtpMethod,
      getSessionIdAndChallengeCodeOtp,
      getOtpFlagValueForAttributes,
      mParticleSignInEvent,
      setCurrentUser,
    ]
  );

  const validateLogin = useCallback(
    async ({ jwt, username }: IValidateLogin) => {
      try {
        const { sessionId, code } = await getSessionIdAndChallengeCode(jwt);
        const session = await cognitoValidateLogin({ username, code, sessionId });
        mParticleSignInEvent({ phase: SignInPhases.COMPLETE, success: true });
        setCurrentUser(session);
        // Add a marker when a user is successfull login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      } catch (error) {
        mParticleSignInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
        });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authError' }),
          modalAppearanceEventMessage: 'Error: JWT Validation Failure',
        });
        throw error;
      }
    },
    [
      formatMessage,
      getSessionIdAndChallengeCode,
      mParticleSignInEvent,
      openErrorDialog,
      setCurrentUser,
    ]
  );

  return {
    authLoading:
      signInMutationLoading ||
      validateAuthMutationLoading ||
      createOtpMutationLoading ||
      validateOtpMutationLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
  };
};
