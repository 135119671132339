import logger from 'utils/logger';

export const BranchEventNames = {
  ADD_TO_CART: 'Add to Cart',
  COMPLETE_REGISTRATION: 'Complete Registration',
  LOGIN: 'Login',
  PURCHASE: 'Purchase',
};

export const logBranchEvent = (
  eventName: typeof BranchEventNames[keyof typeof BranchEventNames],
  customAttributes?: Record<string, any>
) => {
  try {
    window.branch?.logEvent(eventName, customAttributes);
  } catch (error) {
    logger.error({ error, message: `Error logging ${eventName} Event to Branch` });
  }
};
