import gql, { disableFragmentWarnings } from 'graphql-tag';

import VendorConfigFragment from './vendor-config';

disableFragmentWarnings();

export default gql`
  fragment VendorConfigsFragment on VendorConfigs {
    partner {
      ...VendorConfigFragment
    }
    partnerDelivery {
      ...VendorConfigFragment
    }
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    qst {
      ...VendorConfigFragment
    }
    qstDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
  }

  ${VendorConfigFragment}
`;
