import { useCallback } from 'react';

import { useMemoAll } from 'hooks/use-memo-all';
import useReadyQueue from 'hooks/use-ready-queue';
import logger from 'utils/logger';

import { logBranchEvent } from './branch.events';

export interface IBranch {
  initBranch: () => void;
  logBranchEvent: (eventName: string, customAttributes?: Record<string, any>) => void;
}

/**
 * branch hook to initialize branch sdk and log branch event
 * @returns IBranch
 */
export const useBranch = (): IBranch => {
  const { drainQueue } = useReadyQueue();

  const initBranch = useCallback(() => {
    import('./init')
      .then(({ default: init }) => {
        init(drainQueue, { disableJourneysBanner: false });
      })
      .catch(error => {
        logger.error({ error, message: 'Branch: import error' });
      });
  }, [drainQueue]);

  return useMemoAll({ logBranchEvent, initBranch });
};

declare global {
  interface Window {
    branch: any;
  }
}
