/**
 *  TODO / NOTE - this isn't using mparticle anymore it needs to be renamed and refactored to be static
 */
import React, { ReactNode, useCallback, useContext, useRef, useState } from 'react';

import { IsoCountryCode2Char } from '@rbilabs/common';
import { AllowedEvent } from '@rbilabs/mparticle-client';
import { isNil, isUndefined, omitBy } from 'lodash-es';
import { centsToDollars } from 'utils';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUserOffersFeedbackEntry } from '@rbi-ctg/offers';
import useEffectOnce from 'hooks/use-effect-once';
import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import useReadyQueue from 'hooks/use-ready-queue';
import { getRoundUpDonations } from 'pages/cart/your-cart/totals/utils';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useAmplitudeContext } from 'state/amplitude';
import {
  IAmplitudeProduct,
  IAmplitudePurchaseEventAttributes,
  IUtmParams,
} from 'state/amplitude/types';
import { BranchEventNames, logBranchEvent } from 'state/branch';
import { logBrazeCommerceEvent, logBrazeCustomEvent } from 'state/braze/log-braze-event';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { useLoggerContext } from 'state/logger/context';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import AuthStorage from 'utils/cognito/storage';
import { RBIBrand, brand, env, getCountry } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import LogRocketHelper from 'utils/logrocket';
import noop from 'utils/noop';
import { isOTPEnabled } from 'utils/otp';
import { getInCodeLocalizedRouteForPath, routes } from 'utils/routing';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  ONE_INDEX_OFFSET,
  SignInPhases,
  TRACKED_PAGES,
} from './constants';
import * as I from './types';
import { useFlagsForUniversalAttributes } from './use-flags-for-universal-attributes';
import {
  booleanToString,
  convertQueryStringToObject,
  convertToSingleCommerceProduct,
  createCRMProducts,
  createProduct,
  expandProductAttributes,
  getCartDataItems,
  isCommerceEvent,
  isExpandableEvent,
  normalizeBooleans,
  reformatAttributesForSingularProduct,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
  toAttributesWithValidLengthValues,
} from './utils';

// Export action from index
export { CustomEventNames, EventTypes } from './constants';
export type { ILogEvent, IMParticleCtx, IAppflowUpdateEventOptions } from './types';

declare global {
  interface Window {
    mParticle: I.IMParticle;
  }
}

type ExcludesNull = <T>(x: T | null) => x is T;

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

interface IAppflowEventAttributes {
  'Displayed Update UI'?: boolean;
  Response: 'Successful' | 'Failure';
  updateVersion?: string;
  totalTimeMs?: number;
}

declare interface ILogPageView {
  pathname: string;
  normalizedAndSanitizedAttrs: any;
  normalizedFlags: any;
}

// returns window location path name
const getSourcePage = () => {
  const pathName = window.location.pathname;
  // removes menu child routes
  const parsedPathName = pathName.replace(/(menu)\/(.*)/i, '$1/');
  return parsedPathName;
};

const getBrowserType = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else if (userAgent.match(/android/i)) {
    browserName = 'Android';
  } else if (userAgent.match(/iphone/i)) {
    browserName = 'iPhone';
  } else {
    browserName = 'Unknown';
  }
  return browserName;
};

const getBrowserVersion = () => {
  const userAgent = navigator.userAgent;
  const browserType = getBrowserType();
  const browserSentence = userAgent.substring(userAgent.indexOf(browserType));

  const indexFirstNumber = browserSentence.search(/\d/);
  const indexFirstSpace = browserSentence.search(/\s/);

  const browserVersion = browserSentence.substring(indexFirstNumber, indexFirstSpace);

  return browserVersion;
};

const getIsMobileWeb = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'TRUE'
    : 'FALSE';
};

export const MParticleContext = React.createContext<I.IMParticleCtx>({
  signInEvent: noop,
  signOutEvent: noop,
  signUpEvent: noop,
  autoSignInEvent: noop,
  loginCRM: noop,
  logoutCRM: noop,
  updateUniversalAttributes: noop,
  updateStaticRoutes: noop,
  updateUserAttributes: noop,
  updateUserLocationPermissionStatus: noop,
  logPageView: noop,
  logCommercePageView: noop,
  logOrderLatencyEvent: noop,
  logPurchase: noop,
  logEvent: noop,
  logRBIEvent: noop,
  logNavigationClick: noop,
  logAddPaymentMethodClick: noop,
  appflowUpdateEvent: noop,
  logOfferActivatedEvent: noop,
  logCheckoutEvent: noop,
  logUpsellAddedEvent: noop,
  logUpsellRemovedEvent: noop,
  logNavBarClickEvent: noop,
  logAddOrRemoveFromCart: noop,
  marketingTileClickEvent: noop,
  setUTMParamsFromUrl: noop,
});

// TODO rename and make static (not a provider) in a future PR
export const useMParticleContext = () => useContext<I.IMParticleCtx>(MParticleContext);

// TODO rename and make static (not a provider) in a future PR
export function MParticleProvider(props: { children: ReactNode }) {
  const { enqueueIfNotDrained } = useReadyQueue();
  const { logger } = useLoggerContext();
  const { locale, language, region } = useLocale();
  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const flagsForUniversalAttributes = useFlagsForUniversalAttributes();
  const { updateUserDeviceId } = useLDContext();
  const [isNewSignUp, setIsNewSignUp] = useState(false);
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  let additionalSignupFields = {} as SignUpFieldsVariations;
  const {
    deviceId,
    logAmplitudeCustomEvent,
    setAmplitudeUserId,
    unsetAmplitudeUserId,
    logAmplitudeRevenueEvent,
    updateAmplitudeUserAttributes,
    updateUserLocationPermissionStatus,
  } = useAmplitudeContext();

  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2Char.KR) {
    additionalSignupFields.gender = signUpFieldsVariations.gender;
    additionalSignupFields.ageFourteen = signUpFieldsVariations.ageFourteen;
    additionalSignupFields.collectionPersonalData = signUpFieldsVariations.collectionPersonalData;
    additionalSignupFields.outsourcePersonalData = signUpFieldsVariations.outsourcePersonalData;
    additionalSignupFields.transferPersonalDataThirdParty =
      signUpFieldsVariations.transferPersonalDataThirdParty;
    additionalSignupFields.transferPersonalDataOverseas =
      signUpFieldsVariations.transferPersonalDataOverseas;
  }

  const isSmallScreen = useMediaQuery(MediaQuery.Mobile);

  // store static routes for logging page views
  const staticRoutes = useRef<string[]>([]);
  // Its possible to attempt to log a page view, before sanity loads the static pages
  // This ref holds these values until static pages has loaded, after which the page view can be logged
  const logPageViewParameters = useRef<ILogPageView>();

  const getUserHasLoyalty = () => (LocalStorage.getItem(Keys.USER)?.loyaltyId ? true : false);
  // store universal attributes for mParticle event/page views without re-rendering for changes in values
  const universalAttributes = useRef<I.IMParticleUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(),
    browserType: getBrowserType(),
    browserVersion: getBrowserVersion(),
    isMobileWeb: getIsMobileWeb(),
    isLoyaltyUser: getUserHasLoyalty(),
    isSmallScreen,
    currentBuild: '',
    ...flagsForUniversalAttributes,
    ...additionalSignupFields,
  });

  const updateDeviceId = enqueueIfNotDrained(async () => {
    updateUserDeviceId(deviceId);
    // Could not track DeviceId inside LogRocketHelper's init's function because it is called before mParticle is initialized
    LogRocketHelper.track(`DeviceId: ${deviceId}`);
  });

  useEffectOnce(() => {
    // Enqueue setting the sessionId and deviceId to prevent race condition
    updateDeviceId();
  });

  /**
   * Updates only the state of the universal Attributes
   */
  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<I.IMParticleUniversalAttributes>) => {
      universalAttributes.current = {
        ...universalAttributes.current,
        ...newAttributes,
        'Source Page': getSourcePage(),
      };
    },
    []
  );

  const deviceTime = useCallback(() => new Date().toLocaleTimeString([], { hour12: false }), []);

  /**
   * Logs a custom event
   */
  const logRBIEvent: I.ILogRBIEvent = useCallback(
    (event: AllowedEvent) => {
      let universalAttrs = sanitizeValues(universalAttributes.current);
      const sessionUrl = LogRocketHelper.getInstance()?.logrocket?.sessionURL;

      universalAttrs = normalizeBooleans(universalAttrs);

      // TODO fix `any` here
      const globalAttributes: any = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        currentScreen: getSourcePage(),
        deviceTime: deviceTime(),
        serviceMode: universalAttrs['Service Mode'],
        pickupMode: universalAttrs['Pickup Mode'],
        appBuild: universalAttrs.currentBuild,
        isMobileWeb: getIsMobileWeb(),
        ...(sessionUrl && { LogRocket_link: sessionUrl }),
      };

      const definedAttributes = {
        ...omitBy(globalAttributes, isUndefined),
        ...omitBy(event.globalAttributes, isUndefined),
        ...omitBy(event.attributes, isUndefined),
      };

      const attributes = toAttributesWithValidLengthValues(definedAttributes);

      // Log to Braze
      if (isCommerceEvent(event.name)) {
        //@ts-ignore
        const products = attributes?.products ?? [];
        logBrazeCommerceEvent(event.name as CustomEventNames, products, attributes);
      } else {
        logBrazeCustomEvent(event.name, attributes);
      }

      // Expand the products if necessary for Amplitude
      //@ts-ignore
      if (isExpandableEvent(event.name)) {
        const products = attributes?.products ?? [];
        const expandedProducts = expandProductAttributes(products);
        logAmplitudeCustomEvent({
          name: event.name,
          attributes: { ...attributes, ...expandedProducts },
        });
      } else {
        logAmplitudeCustomEvent({ name: event.name, attributes });
      }
    },
    [deviceTime, logAmplitudeCustomEvent, region]
  );

  const logEvent = useCallback(
    (eventName: CustomEventNames, eventType: EventTypes, attributes = {}, customFlags = {}) => {
      logRBIEvent({
        name: eventName as any,
        type: eventType as any,
        attributes,
        customFlags: customFlags as any,
      });
    },
    [logRBIEvent]
  );

  const appflowUpdateEvent = ({
    isBlockingUpdate,
    phase,
    success,
    updateVersion,
    totalTimeMs,
  }: I.IAppflowUpdateEventOptions) => {
    const event: CustomEventNames =
      {
        started: CustomEventNames.APPFLOW_UPDATE_STARTED,
        downloaded: CustomEventNames.APPFLOW_UPDATE_DOWNLOADED,
        extracted: CustomEventNames.APPFLOW_UPDATE_EXTRACTED,
        applied: CustomEventNames.APPFLOW_UPDATE_APPLIED,
      }[phase] || CustomEventNames.APPFLOW_UPDATE_UNKNOWN;

    const attributes: IAppflowEventAttributes = {
      Response: success ? 'Successful' : 'Failure',
      updateVersion,
      totalTimeMs,
    };

    if (!isNil(isBlockingUpdate)) {
      attributes[APPFLOW_DISPLAYED_BLOCKING_UI] = isBlockingUpdate;
    }

    // the attributes of type IAppflowUpdateEventOptions are already boolean-normalized
    logEvent(event, EventTypes.Other, attributes);
  };

  const signInEvent = ({ phase, success, message, otpMethod }: I.ISignInEventOptions) => {
    let event;
    const data = {
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
    };

    if (isOTPEnabled(otpMethod)) {
      event =
        phase === SignInPhases.START
          ? CustomEventNames.OTP_SIGN_IN_START
          : CustomEventNames.OTP_SIGN_IN_VALIDATION;
      data['LaunchDarkly Flag Value'] = otpMethod;
    } else {
      event =
        phase === SignInPhases.START
          ? CustomEventNames.SIGN_IN_START
          : CustomEventNames.SIGN_IN_COMPLETE;
    }

    if (phase === SignInPhases.COMPLETE) {
      const cognitoId = AuthStorage.getItem(StorageKeys.USER)?.cognitoId;
      setAmplitudeUserId(cognitoId);
    }

    if (success && phase === SignInPhases.COMPLETE) {
      logBranchEvent(BranchEventNames.LOGIN);
    }

    logEvent(event, EventTypes.Other, data);

    if (event === CustomEventNames.OTP_SIGN_IN_START) {
      logRBIEvent({
        name: 'Sign in with OTP Attempt',
        type: EventTypes.Other,
        attributes: {
          signInType: 'Email',
        },
      });
    }
  };

  const signOutEvent = (success: boolean, message?: string) => {
    setIsNewSignUp(false);
    logEvent(CustomEventNames.SIGN_OUT, EventTypes.Other, {
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
    });
  };

  const signUpEvent = ({ success, message, otpMethod }: I.ISignUpEventOptions) => {
    let event = CustomEventNames.SIGN_UP;
    const data = {
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
      Type: enableSignUpInBE ? 'Backend' : 'Cognito',
    };

    if (isOTPEnabled(otpMethod)) {
      event = CustomEventNames.OTP_SIGN_UP;
      data['LaunchDarkly Flag Value'] = otpMethod;
    }

    if (success) {
      setIsNewSignUp(true);
      logRBIEvent({
        name: 'Sign Up Successful',
        type: EventTypes.Other,
        attributes: {
          signUpType: 'Email',
        },
      });
      const cognitoId = AuthStorage.getItem(StorageKeys.USER)?.cognitoId;
      setAmplitudeUserId(cognitoId);
    }

    logEvent(event, EventTypes.Other, data);
  };

  const autoSignInEvent = ({ success, message, phase }: I.IAutoSignInEventOptions) => {
    logEvent(
      phase === SignInPhases.COMPLETE
        ? CustomEventNames.AUTO_SIGN_IN_COMPLETE
        : CustomEventNames.AUTO_SIGN_IN_START,
      EventTypes.Other,
      {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
      }
    );
  };

  const loginCRM = useCallback(
    ({ customerId = '', ...userAttributes }) => {
      const normalizedAttrs = normalizeBooleans(userAttributes);
      updateUniversalAttributes(normalizedAttrs);

      const cognitoId = customerId || AuthStorage.getItem(StorageKeys.USER)?.cognitoId;

      if (cognitoId) {
        setAmplitudeUserId(customerId);
      }

      // fire log sign up flow successful after successful login
      if (isNewSignUp) {
        logRBIEvent({
          name: CustomEventNames.SIGN_UP,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
          // they want this event but typescript complaining due to external libs idea of what types should exist.
          // this all needs a drastic refactor and will do it soon after we get off mparticle.
        } as any);

        logRBIEvent({
          name: CustomEventNames.SIGN_UP_FLOW_SUCCESSFUL,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
        });
      }
    },
    [isNewSignUp, logRBIEvent, setAmplitudeUserId, updateUniversalAttributes]
  );

  const logoutCRM = useCallback(() => {
    unsetAmplitudeUserId();
  }, [unsetAmplitudeUserId]);

  const logAddOrRemoveFromCart = useCallback<I.IMParticleCtx['logAddOrRemoveFromCart']>(
    ({ action, cartEntry, previousCartEntries, selectionAttrs }) => {
      const product = createProduct(cartEntry);
      if (!product) {
        return;
      }
      if (action === 'add') {
        logBranchEvent(BranchEventNames.ADD_TO_CART);
      }

      const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
      const attributes = {
        isDonation: cartEntry.isDonation ?? false,
        isExtra: cartEntry.isExtra ?? false,
        cartId,
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
        'Total Amount': product.total_product_amount,
        'Pickup Mode': universalAttributes.current?.['Pickup Mode'] ?? '',
      };

      logRBIEvent({
        //@ts-ignore
        name:
          action === 'add'
            ? CustomEventNames.E_COMMERCE_ADD_TO_CART
            : CustomEventNames.E_COMMERCE_REMOVE_FROM_CART,
        type: EventTypes.Other,
        attributes: { ...attributes, products: [product] },
      });

      const singleProduct = convertToSingleCommerceProduct(product);

      logRBIEvent({
        //@ts-ignore
        name:
          action === 'add' ? 'eCommerce - AddToCart - Item' : 'eCommerce - RemoveFromCart - Item',
        type: EventTypes.Other,
        attributes: { products: [], ...attributes, ...singleProduct },
      });
    },
    [deviceTime, logRBIEvent]
  );

  const logPurchase = useCallback(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs = {}
    ) => {
      const appliedOffersCmsIds = (serverOrder.cart.appliedOffers || []).map(
        ({ sanityId }) => sanityId
      );

      const couponIDs = (serverOrder.cart.offersFeedback || []).map(
        (feedbackEntry: IUserOffersFeedbackEntry) => {
          return feedbackEntry.couponId;
        }
      );

      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      const couponIdsArray = couponIDs.length ? couponIDs : appliedOffersCmsIds;
      const couponIDString = couponIdsArray.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        id: serverOrder.rbiOrderId,
        revenue: centsToDollars(serverOrder.cart.subTotalCents),
        tax: centsToDollars(serverOrder.cart.taxCents),
      };

      const roundUpDonation = getRoundUpDonations(serverOrder);
      const products = createCRMProducts({ serverOrder, cartEntries });

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: IAmplitudePurchaseEventAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(couponIdsArray.length > 0),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.tax,
        'Total Amount': transactionAttributes.revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Home Delivery': store.hasDelivery,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Kiosk': false,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': String(upsellTotal),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        roundUpAmount: roundUpDonation?.totalCents ?? 0,
        'Currency Code': attrs.currencyCode || 'USD',
        'Product Count': products.length,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
      }

      if (transactionAttributes.revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);
      const sanitizedAdditionAttrs = sanitizeValues(additionalAttrs);
      const normalizedAttrs = normalizeBooleans(sanitizedAdditionAttrs);

      try {
        logBranchEvent(BranchEventNames.PURCHASE, {
          revenue: transactionAttributes.revenue,
          tax: transactionAttributes.tax,
          transactionID: serverOrder.rbiOrderId,
          currency: attrs?.currencyCode || 'USD',
        });

        logRBIEvent({
          //@ts-ignore
          name: 'eCommerce - Purchase',
          type: EventTypes.Other,
          attributes: {
            ...normalizedAttrs,
            ...normalizedTransactionAttrs,
            products,
          },
        });

        for (const product of products) {
          const reformattedProduct = reformatAttributesForSingularProduct(product);
          logRBIEvent({
            //@ts-ignore
            name: CustomEventNames.E_COMMERCE_PURCHASE_ITEM,
            type: EventTypes.Other,
            attributes: {
              products: [],
              ...normalizedAttrs,
              ...normalizedTransactionAttrs,
              ...reformattedProduct,
            },
          });
        }

        logAmplitudeRevenueEvent({
          totalAmount: transactionAttributes.revenue,
          eventProperties: { ...normalizedAttrs },
        });
      } catch (error) {
        logger.error({ error, message: 'Error logging eCommerce - Purchase event' });
      }

      try {
        // Also send a custom event for the Purchase allowing the brand to
        // create custom queries in mParticle
        logEvent(CustomEventNames.PURCHASE, EventTypes.Transaction, normalizedAttrs);
      } catch (error) {
        logger.error({ error, message: 'mParticle > purchase custom event error' });
      }

      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup' ? 'Purchase Pick Up' : 'Purchase Delivery';

        try {
          logRBIEvent({
            name: eventName,
            type: EventTypes.Other,
          });
        } catch (error) {
          logger.error({
            error,
            message: `mParticle > ${serializedServiceMode} custom event error`,
          });
        }

        try {
          logBranchEvent(eventName);
        } catch (error) {
          logger.error({
            error,
            message: `Branch > ${serializedServiceMode} custom event error`,
          });
        }
      }
    },
    [deviceTime, logAmplitudeRevenueEvent, logEvent, logRBIEvent, logger, region]
  );

  const updateStaticRoutes = (newStaticRoutes: IStaticPageRoute[]) => {
    staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
      const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
      if (staticPath) {
        acc.push(`/${staticPath}`);
      }
      return acc;
    }, []);

    if (staticRoutes.current.length && logPageViewParameters.current) {
      maybeTrackPage({ ...logPageViewParameters.current });
      logPageViewParameters.current = undefined;
    }
  };

  const maybeTrackPage = enqueueIfNotDrained(
    ({ pathname, normalizedAndSanitizedAttrs }: ILogPageView) => {
      const trackedPages = TRACKED_PAGES.concat(staticRoutes.current);
      const matchedPages = trackedPages.filter(page => pathname.startsWith(page));
      if (matchedPages.length) {
        // Find the longest match by setting it to be the first element
        const matchedPage = matchedPages.sort((a, b) => b.length - a.length)[0];
        logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            path: matchedPage,
            ...normalizedAndSanitizedAttrs,
          },
        });
      }
    }
  );

  const maybeTrackWhenStaticRoutesAvailable = ({
    pathname,
    normalizedAndSanitizedAttrs,
    normalizedFlags,
  }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      normalizedAndSanitizedAttrs,
      normalizedFlags,
    };
  };

  const logPageView = enqueueIfNotDrained(
    (pathname, attrs = {}, customFlags = {}, force = false) => {
      const allAttributes = {
        ...universalAttributes.current,
        ...attrs,
        'Device Time': deviceTime(),
      };
      const sanitizedAttributes = sanitizeValues(allAttributes);
      const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
      const normalizedFlags = normalizeBooleans(customFlags);

      if (force) {
        return logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            ...normalizedAndSanitizedAttrs,
          },
        });
      }
      if (pathname.startsWith(routes.menu)) {
        const onMainMenu = new RegExp(routes.menu.concat('$')).test(pathname);
        if (onMainMenu) {
          return logRBIEvent({
            name: CustomEventNames.PAGE_VIEW,
            type: EventTypes.Other,
            attributes: {
              ...normalizedAndSanitizedAttrs,
            },
          });
        }
      }
      // fixes issue with home page not being captured
      if (pathname === '/') {
        logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            ...normalizedAndSanitizedAttrs,
          },
        });
      } else {
        const trackParameters = {
          pathname,
          normalizedAndSanitizedAttrs,
          normalizedFlags,
        };
        // Checking if path is local path
        const isLocalRoute = Object.values(routes).some(route => {
          const localizedRoute = getInCodeLocalizedRouteForPath(route, locale, region) || route;
          return route !== '/' && pathname.startsWith(localizedRoute);
        });
        // If staticRoutes have not loaded yet
        // Store them for later when they are available
        if (!isLocalRoute && !staticRoutes.current.length) {
          return maybeTrackWhenStaticRoutesAvailable(trackParameters);
        }
        maybeTrackPage({ ...trackParameters });
      }
    }
  );

  const logCommercePageView = (
    menuData: { id: string; name: string; menuType: string },
    attrs = {}
  ) => {
    try {
      const { name, id, menuType } = menuData;
      const product: Pick<
        IAmplitudeProduct,
        'id' | 'quantity' | 'name' | 'price' | 'total_product_amount'
      > = {
        id,
        name,
        quantity: 1,
        price: 0,
        total_product_amount: 0,
      };

      const singleProduct = convertToSingleCommerceProduct(product);
      const viewDetailAttributes = { menuType, ...attrs };
      const viewDetailItemAttributes = { menuType, products: [], ...singleProduct, ...attrs };

      logRBIEvent({
        //@ts-ignore
        name: 'eCommerce - ViewDetail',
        type: EventTypes.Other,
        attributes: { ...viewDetailAttributes, products: [product] },
      });

      logRBIEvent({
        //@ts-ignore
        name: 'eCommerce - ViewDetail - Item',
        type: EventTypes.Other,
        attributes: {
          ...viewDetailItemAttributes,
        },
      });
    } catch (error) {
      logger.error(`Error logging CRM eCommerce - View Detail event`);
    }
  };

  const logNavigationClick = (eventName: CustomEventNames, attrs = {}, customFlags = {}) => {
    logEvent(
      CustomEventNames.BUTTON_CLICK,
      EventTypes.Navigation,
      {
        Name: eventName,
        ...attrs,
      },
      customFlags
    );
  };

  const logAddPaymentMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_ADD_PAYMENT_METHOD);
  };

  const logOfferActivatedEvent = (sanityId: string, offerName: string, tokenId?: string | null) => {
    logEvent(CustomEventNames.OFFER_ACTIVATED, EventTypes.Other, {
      'Sanity ID': sanityId,
      'Offer Name': offerName,
      external_offer_id: tokenId,
    });
  };

  /**
   * Logs an event to mparticle with the duration in MS an order has taken
   * to transition from either PRICE_REQUESTED or INSERT_REQUESTED to
   * *_ERROR/SUCCESSFUL status
   */
  const logOrderLatencyEvent = enqueueIfNotDrained(
    (order: IServerOrder | undefined, actionType: 'commit' | 'price', duration: number) => {
      const orderStatus = order?.status;
      const storeId = order?.cart?.storeDetails?.storeNumber;
      const orderId = order?.rbiOrderId;
      const serviceMode = order?.cart?.serviceMode ?? null;

      const eventName =
        actionType === 'price'
          ? CustomEventNames.ORDER_LATENCY_PRICING
          : CustomEventNames.ORDER_LATENCY_COMMIT;

      logEvent(eventName, EventTypes.Other, {
        Duration: Math.floor(duration),
        Status: orderStatus,
        'Store ID': storeId,
        'Order ID': orderId,
        'Service Mode': serializeServiceMode(serviceMode),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
      });
    }
  );

  const logUpsellAddedEvent = enqueueIfNotDrained((item: ICartEntry, itemPosition?: number) => {
    if (!item.isUpsell) {
      return;
    }
    const { name, price } = item;
    logEvent(CustomEventNames.UPSELL_ADDED, EventTypes.Other, {
      name,
      sanityId: item._id,
      price: price && price / 100,
      upsellItemPosition: itemPosition,
    });
  });

  const logUpsellRemovedEvent = enqueueIfNotDrained((item: ICartEntry) => {
    if (!item.isUpsell) {
      return;
    }
    const { recommender, recommendationToken, price, name } = item;

    logEvent(CustomEventNames.UPSELL_REMOVED, EventTypes.Other, {
      Id: item._id,
      Name: name,
      Price: price && price / 100,
      Recommender: recommender,
      RecommendationToken: recommendationToken,
      'Source Page': getSourcePage(),
    });
  });

  const logCheckoutEvent = (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
    try {
      const products = cartEntries.map(createProduct).filter((Boolean as any) as ExcludesNull);
      const pickUpMode = serializePickupMode(serviceMode);
      const customAttributes = {
        'Pickup Mode': pickUpMode,
        'Cart Data': getCartDataItems(cartEntries),
        'Total Amount': products.reduce((acc, curr) => acc + curr.total_product_amount, 0),
        'Product Count': products.length,
      };

      logRBIEvent({
        //@ts-ignore
        name: 'eCommerce - Checkout',
        type: EventTypes.Other,
        attributes: { ...customAttributes, products },
      });

      for (const product of products) {
        const singleProduct = convertToSingleCommerceProduct(product);
        logRBIEvent({
          //@ts-ignore
          name: 'eCommerce - Checkout - Item',
          type: EventTypes.Other,
          attributes: { ...customAttributes, ...singleProduct },
        });
      }
    } catch (error) {
      logger.error('Error logging CRM eCommerce - Checkout event');
    }
  };

  const marketingTileClickEvent = (title: string, position: number, cardId: string) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.MARKETING_TILE,
        text: title,
        position: `Tile ${position + ONE_INDEX_OFFSET}`,
        componentId: cardId,
      },
    });
  };

  const logNavBarClickEvent = (text: string, componentKey: string) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.NAV_BAR,
        text,
        componentId: componentKey,
      },
    });
  };

  /**
   * Updates UTM Attributes in the universal attributes.
   * Used For attribution tracking.
   */
  const setUTMParamsFromUrl = useCallback(
    (url: string) => {
      const {
        utm_source = '',
        utm_campaign = '',
        utm_medium = '',
        utm_content = '',
        utm_term = '',
      } = convertQueryStringToObject<IUtmParams>(url);

      if (utm_source || utm_campaign || utm_medium || utm_content || utm_term) {
        updateUniversalAttributes({
          'UTM Source': utm_source,
          'UTM Medium': utm_medium,
          'UTM Campaign': utm_campaign,
          'UTM Term': utm_term,
          'UTM Content': utm_content,
        });
      }
    },
    [updateUniversalAttributes]
  );

  const updateUserAttributes = useCallback(
    attrs => {
      updateAmplitudeUserAttributes(attrs);
      logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
        'Promotional Emails': attrs.promotionalEmails,
      });
    },
    [logEvent, updateAmplitudeUserAttributes]
  );

  return (
    <MParticleContext.Provider
      value={{
        signInEvent,
        signOutEvent,
        signUpEvent,
        loginCRM,
        logoutCRM,
        autoSignInEvent,
        updateStaticRoutes,
        updateUserAttributes,
        updateUserLocationPermissionStatus,
        logPageView,
        logCommercePageView,
        logOrderLatencyEvent,
        logPurchase,
        logEvent,
        logRBIEvent,
        logNavigationClick,
        logAddPaymentMethodClick,
        appflowUpdateEvent,
        logOfferActivatedEvent,
        logCheckoutEvent,
        logUpsellAddedEvent,
        logUpsellRemovedEvent,
        logNavBarClickEvent,
        marketingTileClickEvent,
        logAddOrRemoveFromCart,
        updateUniversalAttributes,
        setUTMParamsFromUrl,
      }}
    >
      {props.children}
    </MParticleContext.Provider>
  );
}

export default MParticleContext.Consumer;
