import styled from 'styled-components';

export const P = styled.p`
  display: none;
  color: ${Styles.color.grey.four};
  font-size: 0.9375rem;
  margin: 0;
  padding: 0.5rem 0;

  ${Styles.desktop`
    display: block;
  `}
`;

export const Header = styled.h2`
  color: ${Styles.color.white};
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;

  ${Styles.desktop`
    margin-bottom: auto;
  `}
`;

export const Subheader = styled.div`
  color: ${Styles.color.white};
`;
