import React from 'react';

import { Box, Button } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IBaseProps, OnClick } from '@rbi-ctg/frontend';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import UnstyledButton from 'components/unstyled-button';
import useMediaQuery from 'hooks/use-media-query';
import { fullBrandName, getConfigValue } from 'utils/environment';

import { ForceAppUpdateVariations } from './types';

const StyledButton = styled(Button)`
  margin: 0 0 0.5rem 0;
  ${Styles.desktop`
    margin: 0 1.5rem 0 0;
  `}
`;

const StyledDesc = styled.p`
  margin-bottom: 0.5rem;
`;

const StyledModal = styled(Modal)<{ $isMediumVariation?: boolean }>`
  & .modal-scroller {
    padding: 0;
    display: flex;
  }
  ${Styles.desktop`
    width: 35vw !important;
    height: ${(p: { $isMediumVariation: boolean }) =>
      p.$isMediumVariation ? '18.5rem' : '10rem'} !important;

    & p {
      margin: 0;
    }
  `}
`;

const StyledModalContent = styled(ModalContent)<{ $isMediumVariation?: boolean }>`
  ${Styles.mobile`
    height: ${(p: { $isMediumVariation: boolean }) => (p.$isMediumVariation ? '18.5rem' : '10rem')};
    width: 90vw;
    left: 5vw;
    top: calc((100vh -  ${(p: { $isMediumVariation: boolean }) =>
      p.$isMediumVariation ? '35vh' : '25vh'})/2);

  `}
  border-radius: 1.25rem;
`;

const StyledTitle = styled.h6`
  font-size: 1.25rem;
  font-weight: ${Styles.fontWeight.heavy};
  margin: 0;
  margin-bottom: 0.5rem;
`;

const StyledHeader = styled.h5`
  font-weight: ${Styles.fontWeight.heavy};
  margin: 0;
`;

export interface IForceUpdateDialogProps extends IBaseProps {
  onDismiss?: OnClick;
  forceUpdatePriority?: string;
}

const ForceUpdateDialog = ({ forceUpdatePriority, onDismiss }: IForceUpdateDialogProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery('mobile');

  const redirectToAppStore = () => {
    window.location.href = getConfigValue('appStoreDeeplink');
  };

  const mediumVariation = ForceAppUpdateVariations.MEDIUM === forceUpdatePriority;
  const highVariation = ForceAppUpdateVariations.HIGH === forceUpdatePriority;

  return (
    <StyledModal
      data-testid="force-update-dialog"
      $isMediumVariation={mediumVariation}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Force update app',
      }}
      size={ModalSize.SMALL}
      allowsDismiss={false}
      backgroundColor="transparent"
    >
      <StyledModalContent $isMediumVariation={mediumVariation}>
        <StyledHeader>{formatMessage({ id: 'updateRequired' })}</StyledHeader>
        {mediumVariation && (
          <StyledTitle data-testid="medium-variation-title">
            {formatMessage({ id: 'newUpdateRequired' })}
          </StyledTitle>
        )}
        <StyledDesc>
          {formatMessage(
            { id: mediumVariation ? 'inOrderToContinue' : 'youNeedNewestVersion' },
            { appName: fullBrandName() }
          )}
        </StyledDesc>
        {mediumVariation && (
          <Box
            margin="0.5rem 0 0 0"
            width="100%"
            flexDirection={isMobile ? 'column' : 'row'}
            justify={isMobile ? 'center' : 'flex-end'}
          >
            <StyledButton
              data-testid="medium-variation-force-update-app-button"
              onClick={redirectToAppStore}
            >
              {formatMessage({ id: 'updateTheApp' })}
            </StyledButton>
            <UnstyledButton onClick={onDismiss} data-testid="force-update-app-cancel-button">
              {formatMessage({ id: 'cancel' })}
            </UnstyledButton>
          </Box>
        )}
        {highVariation && (
          <Box width="100%" justify="flex-end">
            <UnstyledButton
              onClick={redirectToAppStore}
              data-testid="high-variation-force-update-app-button"
            >
              {formatMessage({ id: 'updateApp' })}
            </UnstyledButton>
          </Box>
        )}
      </StyledModalContent>
    </StyledModal>
  );
};

export default ForceUpdateDialog;
