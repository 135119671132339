import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { differenceInMinutes } from 'date-fns';
import { cloneDeep, differenceBy, intersectionBy, isEqual } from 'lodash-es';
import { useIntl } from 'react-intl';
import uuidv4 from 'uuid/v4';

import { OfferDiscountTypes } from 'enums/menu';
import { useLoyaltyUserTransactionsQuery } from 'generated/graphql-gateway';
import {
  GetOrderDocument,
  RbiOrderStatus,
  useGetLoyaltyCardTransactionsLazyQuery,
  useGetUserOrdersLazyQuery,
  usePriceOrderMutation,
  useUpdateOrderMutation,
} from 'generated/rbi-graphql';
import { useLoyaltyActiveCard } from 'hooks/loyalty';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import { useAuthContext } from 'state/auth';
import { useErrorContext } from 'state/errors';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { removeAppliedOffersInStorage } from 'state/global-state/models/loyalty/offers/offers.utils';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { EnablePremiumComboSlotsVariations } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useLoggerContext } from 'state/logger/context';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getCmsOffersMapByCmsId } from 'state/loyalty/utils';
import { useMParticleContext } from 'state/mParticle';
import { CustomEventNames, EventTypes } from 'state/mParticle/constants';
import { useMainMenuContext as useNewMainMenuContext } from 'state/main-menu/new-main-menu';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { useCartTip } from 'state/order/hooks/use-cart-tip';
import { usePaymentContext } from 'state/payment';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { useStoreContext } from 'state/store';
import { usePosDataQuery } from 'state/store/hooks/use-pos-data-query';
import { useUIContext } from 'state/ui';
import { getUnavailableCartEntries } from 'utils/availability';
import {
  CartEntryType,
  buildPriceDeliveryInput,
  buildStoreAddress,
  createCartEntry,
  remappedCartForBackEnd,
} from 'utils/cart';
import {
  computeCartTotal,
  computeOtherDiscountAmount,
  computeTotalWithoutOffers,
} from 'utils/cart/helper';
import { redeemReward } from 'utils/cart/redeem-reward';
import * as DatadogLogger from 'utils/datadog';
import { brand, platform } from 'utils/environment';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import noop from 'utils/noop';
import { routes } from 'utils/routing';
import { isCatering as isCateringOrder } from 'utils/service-mode';
import { Measures, PerformanceMarks, getMeasureAndClearMarks, setMark } from 'utils/timing';
import { toast } from 'utils/toast';

import { TipAmounts } from './constants';
import { checkLimitReachedEvent, checkMinimumNotReachedEvent } from './custom-events';
import useAlertOrderCateringMinimum from './hooks/use-alert-order-catering-min';
import useAlertOrderDeliveryMinimum from './hooks/use-alert-order-delivery-min';
import useAlertOrderLimit from './hooks/use-alert-order-limit';
import { useHandleReorder } from './hooks/use-handle-reorder';
import useRewardDiscount from './hooks/use-reward-discount';
import { useUnavailableCartEntries } from './hooks/use-unavailable-cart-entries';
import { orderPollFailure, orderPollSuccessful } from './order-state-utils';
import refetchTransactions from './refetch-transactions';
import { DeliveryStatus, OrderStatus } from './types';
import {
  determineLimit,
  findEntriesByCmsId,
  replaceEntry,
  replaceEntryArrayItem,
  validateCartEntries,
} from './utils';

export { OrderStatus, ServiceMode, TipAmounts };
export const OrderContext = React.createContext();
export const useOrderContext = () => useContext(OrderContext);

const preloadedOrder = () => {
  const order = LocalStorage.getItem(StorageKeys.ORDER);
  if (order) {
    const { curbsidePickupOrderTimePlaced } = order;
    // We time out saved curbside order after 1 hour
    // if user has not submitted the order to prevent
    // outdated order
    if (
      !curbsidePickupOrderTimePlaced ||
      differenceInMinutes(new Date(), new Date(curbsidePickupOrderTimePlaced)) > 60
    ) {
      return {
        ...order,
        curbsidePickupOrderTimePlaced: '',
        curbsidePickupOrderId: '',
      };
    }
    return order;
  }
  return {};
};

export function OrderProvider(props) {
  const apolloClient = useApolloClient();
  const preloaded = useMemo(() => preloadedOrder(), []);

  // LD Flags
  const premiumComboSlotPricingMethod = useFlag(LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS);
  const deliveryBannerPolling = useFlag(LaunchDarklyFlag.DELIVERY_BANNER_POLLING);
  const enableStoreLocatorRevamp = useFlag(LaunchDarklyFlag.ENABLE_LOCATOR_UI_REFRESH);
  const tipPercentThresholdCents = useFlag(LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS) || 0;
  const enableMenuServiceData = useFlag(LaunchDarklyFlag.ENABLE_MENU_SERVICE_DATA);
  let CART_VERSION = useFlag(LaunchDarklyFlag.ORDER_CART_VERSION) || preloaded.cartVersion;
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const auth = useAuthContext();
  const billingCountry = auth?.user?.details?.isoCountryCode || ISOs.USA;
  const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
  const { locale: customerLocale } = useLocale();
  const { decorateLogger, logger } = useLoggerContext();
  const mParticle = useMParticleContext();
  const {
    pricingFunction,
    priceForItemOptionModifier,
    priceForItemInComboSlotSelection,
  } = useMenuContext();
  const { getPaymentMethods } = usePaymentContext();
  const {
    tipAmount,
    setTipAmount,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  } = useCartTip();

  const {
    prices,
    resetStore,
    selectStore: selectNewStore,
    store,
    resetLastTimeStoreUpdated,
    isStoreOpenAndAvailable,
    updateUserStoreWithCallback,
  } = useStoreContext();
  const { storeMenuLoading } = useNewMainMenuContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const { location, navigate } = useLocationContext();
  const offers = useOffersContext();
  const { setCurrentOrderId } = useErrorContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { getAvailableRewardFromCartEntry, refetchLoyaltyUser } = useLoyaltyContext();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const incentivesIds = useAppSelector(selectors.loyalty.selectIncentivesIds);

  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const dispatch = useAppDispatch();

  const loyaltyUserId = loyaltyUser?.id;

  const { refetchActiveCard, activeCard } = useLoyaltyActiveCard();
  const { cardId, barcode } = activeCard || {};
  const [refetchActiveCardTransactions] = useGetLoyaltyCardTransactionsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      cardId,
    },
  });
  const { refetch: refetchLoyaltyUserTransaction } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId || '' },
  });
  const [refetchGetUserOrders] = useGetUserOrdersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 1,
      orderStatuses: [RbiOrderStatus.INSERT_SUCCESSFUL, RbiOrderStatus.UPDATE_SUCCESSFUL],
    },
  });

  const [executeUpdateOrderMutation] = useUpdateOrderMutation();
  const [executePriceOrderMutation] = usePriceOrderMutation();

  const [pendingReorder, setPendingReorder] = useState(null);
  const [reordering, setReordering] = useState(false);
  const [reorderedOrderId, setReorderedOrderId] = useState(null);

  const [cartIdEditing, setCartIdEditing] = useState(preloaded.cartIdEditing || '');
  const [cartEntries, setCartEntries] = useState(preloaded.cartEntries || []);

  const [curbsidePickupOrderId, setCurbsidePickupOrderId] = useState(
    preloaded.curbsidePickupOrderId || ''
  );
  const [curbsidePickupOrderTimePlaced, setCurbsidePickupOrderTimePlaced] = useState(
    preloaded.curbsidePickupOrderTimePlaced || ''
  );
  const [serverOrder, setServerOrder] = useState({});
  const [quoteId, setQuoteId] = useState(preloaded.quoteId || '');
  const [cateringPickupDateTime, setOrderCateringPickupDateTime] = useState(
    preloaded.cateringPickupDateTime || ''
  );
  const [deliveryAddress, setDeliveryAddress] = useState(preloaded.deliveryAddress || {});
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    preloaded.deliveryInstructions || ''
  );
  const { refetch: getPosData } = usePosDataQuery({
    lazy: true,
    restaurantPosDataId: '',
    storeNumber: '',
  });
  const [fetchingPosData, setFetchingPosData] = useState(false);

  const [orderPhoneNumber, setOrderPhoneNumber] = useState(() => preloaded.orderPhoneNumber || '');
  React.useEffect(() => {
    if (orderPhoneNumber) {
      return;
    }
    if (auth?.user?.details?.phoneNumber) {
      setOrderPhoneNumber(auth?.user?.details?.phoneNumber);
    }
  }, [auth, orderPhoneNumber]);

  const [isPricingOrder, setIsPricingOrder] = useState(false);
  const [fireOrderIn, setFireOrderIn] = useState(0);
  const [cartPriceLimitExceeded, setCartPriceLimitExceeded] = useState(false);
  const [cartPriceTooLow, setCartPriceTooLow] = useState(false);
  const [cartCateringPriceTooLow, setCartCateringPriceTooLow] = useState(false);

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const refPriceOrderTimeout = useRef(null);

  const { unavailableCartEntries, setUnavailableCartEntries } = useUnavailableCartEntries({
    serverOrder,
    cartEntries,
  });
  const [pendingRecentItem, setPendingRecentItem] = useState();
  const [pendingRecentItemNeedsReprice, setPendingRecentItemNeedsReprice] = useState(false);

  // th specific: reward information
  const { cartHasRewardEligibleItem } = useRewardDiscount({
    serverOrder,
    cartEntries,
  });

  const updateShouldSaveDeliveryAddress = useCallback(shouldSaveDeliveryAddress => {
    setDeliveryAddress(previousAddress => ({
      ...previousAddress,
      shouldSave: shouldSaveDeliveryAddress,
    }));
  }, []);

  const orderLimitMessage = (maxLimit, maxCateringLimit, isOrderCatering) => {
    const limit = determineLimit({ maxLimit, maxCateringLimit, isCatering: isOrderCatering });
    return formatMessage(
      { id: 'orderLimitMessage' },
      {
        maxLimit: formatCurrencyForLocale(limit),
      }
    );
  };

  const shouldOfferBeRemoved = useCallback(
    item => {
      const cartEntriesWithRemovedItem = cartEntries.filter(entry => entry._id !== item._id);
      const offerDetails = {
        selectedOffer: offers.selectedOffer,
        selectedOfferCartEntry: offers.selectedOfferCartEntry,
        selectedOfferPrice: offers.selectedOfferPrice,
      };

      let total = computeCartTotal(offerDetails, cartEntriesWithRemovedItem, {
        loyaltyEnabled,
        appliedLoyaltyRewards,
        appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
      });
      total = total < 0 ? 0 : total;

      const offerPrice = offers?.selectedOffer?.option?.discountValue || 0;
      // TODO: this line does not account for percentage type discounts
      return total < offerPrice * 100;
    },
    [appliedLoyaltyRewards, cartEntries, loyaltyEnabled, offers, discountAppliedCmsOffers]
  );

  const getOfferText = item =>
    shouldOfferBeRemoved(item) ? formatMessage({ id: 'removeItemFromCartOfferText' }) : '';

  const removeItemMessage = item => {
    return item
      ? formatMessage(
          { id: 'removeItemFromCart' },
          {
            item: item.name,
            offerText: getOfferText(item),
          }
        )
      : '';
  };

  const emptyCart = useCallback(() => {
    setCartEntries([]);
    setCartIdEditing('');
    offers.clearSelectedOffer({ doLog: false });
    offers.setRedemptionMethod(null);
    setTipAmount(0);
    setTipSelection({
      percentAmount: TipAmounts.PERCENT_DEFAULT,
      dollarAmount: TipAmounts.DOLLAR_DEFAULT,
      otherAmount: 0,
      isOtherSelected: false,
    });

    removeAppliedOffersInStorage();
    removeAppliedRewardsInStorage();

    if (loyaltyEnabled) {
      dispatch(actions.loyalty.resetAppliedOffers());
      dispatch(actions.loyalty.resetLoyaltyRewardsState(loyaltyUser?.points ?? 0));
    }
  }, [loyaltyEnabled, loyaltyUser, offers, dispatch, setTipAmount, setTipSelection]);

  useSetResetCartTimeout({
    storageKey: StorageKeys.ORDER_LAST_UPDATE,
    cart: cartEntries,
    resetCartCallback: emptyCart,
  });

  useEffectOnUpdates(() => {
    if (!auth.isAuthenticated()) {
      emptyCart();
    }
  }, [auth.user]); // eslint-disable-line react-hooks/exhaustive-deps

  const verifyCartVersion = useCallback(
    (version, { onFailure = noop, onSuccess = noop }) => {
      if (version !== CART_VERSION) {
        onFailure();
        return;
      }
      onSuccess();
    },
    [CART_VERSION]
  );

  useEffect(() => {
    const updateStoredCartVersion = () =>
      LocalStorage.setItem(StorageKeys.ORDER, {
        ...preloaded,
        cartVersion: CART_VERSION,
      });

    const cartVersionArgs = {
      message: cartEntries.length > 0 ? formatMessage({ id: 'outdatedCartVersionMessage' }) : '',
      onFailure: () => {
        if (cartEntries.length > 0) {
          emptyCart();
        }
        updateStoredCartVersion();
      },
    };

    verifyCartVersion(preloaded.cartVersion || 0, cartVersionArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CART_VERSION]);

  useEffect(() => {
    setCurrentOrderId(serverOrder.rbiOrderId);
  }, [serverOrder, setCurrentOrderId]);

  useEffectOnUpdates(() => {
    // Creating a map out of all valid offers in the CMS
    const cmsOffersMap = getCmsOffersMapByCmsId(loyaltyCmsOffers);
    appliedOffers.forEach(({ cartId, cmsId }) => {
      // If the applied offer is not in the cms offers map, should remove it
      const shouldRemoveCartEntry = !cmsId || !cmsOffersMap[cmsId];
      if (shouldRemoveCartEntry) {
        // Removing offer from cart will also remove it from applied offers
        removeFromCart({ cartId });
      }
    });
  }, [loyaltyCmsOffers]);

  // for confirming before changing modes
  const onConfirmNewMode = useCallback(
    ({ newMode, resolve }) => {
      mParticle.logRBIEvent({
        name: CustomEventNames.SELECT_SERVICE_MODE,
        type: EventTypes.Other,
        attributes: null,
      });
      setServiceMode(newMode);
      emptyCart();
      resetLastTimeStoreUpdated();
      return resolve(true);
    },
    [emptyCart, mParticle, setServiceMode, resetLastTimeStoreUpdated]
  );

  const [ServiceModeDialog, openSvcModeDialog] = useDialogModal({
    onConfirm: onConfirmNewMode,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Selecting new store may change prices',
  });

  const selectServiceMode = useCallback(
    newMode => {
      const isChangingToOrFromDelivery =
        newMode === ServiceMode.DELIVERY || serviceMode === ServiceMode.DELIVERY;
      // If we're changing from or to Catering mode and there is items in the cart, show the svc mode modal
      const isChangingToOrFromCatering = isCateringOrder(newMode) !== isCateringOrder(serviceMode);

      if (
        !enableStoreLocatorRevamp &&
        (isChangingToOrFromDelivery || isChangingToOrFromCatering) &&
        cartEntries.length
      ) {
        return new Promise(resolve => {
          if (serviceMode !== newMode) {
            openSvcModeDialog({ newMode, resolve });
          } else {
            resolve(true);
          }
        });
      }

      mParticle.logRBIEvent({
        name: CustomEventNames.SELECT_SERVICE_MODE,
        type: EventTypes.Other,
        attributes: null,
      });
      setFireOrderIn(0);
      setTipSelection({
        percentAmount: TipAmounts.PERCENT_DEFAULT,
        dollarAmount: TipAmounts.DOLLAR_DEFAULT,
        otherAmount: 0,
        isOtherSelected: false,
      });
      setServiceMode(newMode);
      resetLastTimeStoreUpdated();
      return Promise.resolve(true);
    },
    [
      serviceMode,
      cartEntries.length,
      mParticle,
      setTipSelection,
      setServiceMode,
      resetLastTimeStoreUpdated,
      enableStoreLocatorRevamp,
      openSvcModeDialog,
    ]
  );

  const {
    onConfirmRedemption,
    selectedOffer,
    isSelectedOfferCartEntry,
    selectedOfferCartEntry,
    clearSelectedOffer,
  } = offers;

  const logCartEntryRemovedFromCart = useCallback(
    cartEntry => {
      mParticle.logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry,
        previousCartEntries: cartEntries,
      });
      if (cartEntry.isUpsell) {
        mParticle.logUpsellRemovedEvent(cartEntry);
      }
    },
    [cartEntries, mParticle]
  );

  const removeOfferFromCart = useCallback(() => {
    return selectedOffer && clearSelectedOffer();
  }, [clearSelectedOffer, selectedOffer]);

  const removeRewardIfNeeded = useCallback(
    cartEntry => {
      const { cartId } = cartEntry;
      const cartEntryReward = getAvailableRewardFromCartEntry(cartEntry);

      const isRewardApplied = !!appliedLoyaltyRewards?.[cartId]?.timesApplied;
      if (loyaltyEnabled && isRewardApplied && cartEntryReward) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: cartEntryReward.rewardBenefitId,
            cartId,
          })
        );
      }
    },
    [appliedLoyaltyRewards, getAvailableRewardFromCartEntry, loyaltyEnabled, dispatch]
  );

  // Updates the cartIdEditing to the new entry only if it exists
  const editCart = useCallback(
    cartId => {
      if (
        isSelectedOfferCartEntry({ cartId }) ||
        cartEntries.find(({ cartId: entryCartId }) => entryCartId === cartId)
      ) {
        setCartIdEditing(cartId);
      }
    },
    [cartEntries, isSelectedOfferCartEntry]
  );

  // Returns back the currently editing cartEntry or undefined if the entry no longer exists
  const getCurrentCartEntry = useCallback(() => {
    return isSelectedOfferCartEntry({ cartId: cartIdEditing })
      ? selectedOfferCartEntry
      : cartEntries.find(entry => entry.cartId === cartIdEditing);
  }, [cartEntries, cartIdEditing, isSelectedOfferCartEntry, selectedOfferCartEntry]);

  // Removes all provided cartEntries from the cart using their cartIds
  const removeAllFromCart = useCallback(
    (cartEntriesToRemove = []) => {
      const cartEntryIdsToRemove = new Set(cartEntriesToRemove.map(entry => entry.cartId));
      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => !cartEntryIdsToRemove.has(entry.cartId))
      );
      cartEntriesToRemove.forEach(cartEntry => {
        removeRewardIfNeeded(cartEntry);
        logCartEntryRemovedFromCart(cartEntry);
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntry));
      });
    },
    [dispatch, logCartEntryRemovedFromCart, removeRewardIfNeeded]
  );

  // Removes a single cartEntry using its cartId
  const removeFromCart = useCallback(
    ({ cartId }) => {
      // Entry to remove can be an offer cart entry
      const isOffer = selectedOfferCartEntry?.cartId === cartId;
      const cartEntryToRemove = isOffer
        ? selectedOfferCartEntry
        : cartEntries.find(entry => entry.cartId === cartId);

      if (!cartEntryToRemove) {
        return;
      }
      dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntryToRemove));
      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => cartEntryToRemove.cartId !== entry.cartId)
      );
      logCartEntryRemovedFromCart(cartEntryToRemove);

      if (isOffer || shouldOfferBeRemoved(cartEntryToRemove)) {
        removeOfferFromCart();
      }
      // removes applied rewards associated to cart entry on removal
      removeRewardIfNeeded(cartEntryToRemove);

      // Resets the availability of Surprise so it wont show in cart page
      dispatch(actions.loyalty.resetSurpriseAvailability());
    },
    [
      selectedOfferCartEntry,
      cartEntries,
      dispatch,
      logCartEntryRemovedFromCart,
      shouldOfferBeRemoved,
      removeRewardIfNeeded,
      removeOfferFromCart,
    ]
  );

  useEffect(() => {
    if (incentivesIds.size > 0) {
      validateCartEntries(cartEntries, appliedOffers, incentivesIds, removeFromCart);
    }
  }, [appliedOffers, cartEntries, incentivesIds, removeFromCart]);

  const shouldEmptyCart = ({ cartId }) => {
    const appliedOffersMap = appliedOffers.reduce((acc, appliedOffer) => {
      if (appliedOffer.cartId) {
        acc[appliedOffer.cartId] = appliedOffer;
      }
      return acc;
    }, {});

    const entriesNotRemoved = cartEntries.filter(entry => entry.cartId !== cartId);
    const entryIsDonationOrSurprise = entry =>
      entry.isDonation || appliedOffersMap[entry?.cartId]?.isSurprise;

    return entriesNotRemoved.length && entriesNotRemoved.every(entryIsDonationOrSurprise);
  };

  const [RemoveItemDialog, openRemoveItemDialog, itemToRm] = useDialogModal({
    onConfirm: entry => {
      return shouldEmptyCart(entry) ? emptyCart() : removeFromCart(entry);
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Remove item from cart',
  });

  // for confirming item removal
  const confirmRemoveFromCart = useCallback(
    cartId => {
      const isOffer = selectedOfferCartEntry?.cartId === cartId;
      // Entry to remove can be an offer cart entry
      const entryToRemove = isOffer
        ? selectedOfferCartEntry
        : cartEntries.find(item => cartId === item.cartId);
      openRemoveItemDialog(entryToRemove);
    },
    [cartEntries, openRemoveItemDialog, selectedOfferCartEntry]
  );

  const calculateCartTotalWithoutOffers = useCallback(() => {
    return computeTotalWithoutOffers(cartEntries, { loyaltyEnabled, appliedLoyaltyRewards });
  }, [appliedLoyaltyRewards, cartEntries, loyaltyEnabled]);

  const calculateCartTotalWithDiscount = useCallback(() => {
    const total = computeCartTotal(offers, cartEntries, {
      loyaltyEnabled,
      appliedLoyaltyRewards,
      appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
    });
    return {
      cartTotal: total,
      isCartTotalNegative: total < 0,
    };
  }, [appliedLoyaltyRewards, cartEntries, loyaltyEnabled, offers, discountAppliedCmsOffers]);

  const calculateCartTotal = useCallback(() => {
    const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount();
    return isCartTotalNegative ? 0 : cartTotal;
  }, [calculateCartTotalWithDiscount]);

  const logCartStoreAndTimeout = useCallback(
    (resetStoreTimeout, timeSinceLastVisit) => {
      const storeDetails = {
        cartEntriesTotal: cartEntries.length,
        storeId: store._id,
        itemNames: cartEntries.map(entry => entry.name),
      };

      const { cartEntriesTotal, storeId, itemNames } = storeDetails;

      mParticle.logEvent(CustomEventNames.SESSION_RESET_FROM_INACTIVITY, EventTypes.Other, {
        storeDetails: `cartEntriesTotal: ${cartEntriesTotal}, storeId: ${storeId}, itemNames: ${JSON.stringify(
          itemNames
        )} `,
        resetStoreTimeoutSeconds: resetStoreTimeout,
        hoursSinceLastVisit: parseInt((timeSinceLastVisit / (1000 * 60 * 60)).toFixed(1)),
      });
    },
    [cartEntries, mParticle, store._id]
  );

  const clearCartStoreServiceModeTimeout = useCallback(() => {
    selectServiceMode(null);
    setUnavailableCartEntries([]);
    emptyCart();
    resetStore();
    LocalStorage.removeItem(StorageKeys.LAST_TIME_STORE_UPDATED);
  }, [emptyCart, resetStore, selectServiceMode, setUnavailableCartEntries]);

  const checkoutPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT);
  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);
  const checkoutCateringPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_LIMIT);
  const checkoutCateringPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM);

  const [OrderLimitDialog, openOrderLimitDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Order limit reached',
  });

  const alertOrderLimit = useCallback(() => {
    checkLimitReachedEvent(calculateCartTotal(), mParticle);
    if (location.pathname.startsWith(routes.cart)) {
      openOrderLimitDialog();
    }
  }, [calculateCartTotal, mParticle, location.pathname, openOrderLimitDialog]);

  const alertOrderDeliveryMinimum = useCallback(() => {
    if (
      cartEntries.length > 0 &&
      isDelivery &&
      calculateCartTotal() < checkoutDeliveryPriceMinimum
    ) {
      checkMinimumNotReachedEvent(calculateCartTotal(), mParticle);
    }
  }, [mParticle, calculateCartTotal, cartEntries.length, checkoutDeliveryPriceMinimum, isDelivery]);

  const alertOrderCateringMinimum = useCallback(() => {
    if (
      cartEntries.length > 0 &&
      isCateringOrder(serviceMode) &&
      calculateCartTotal() < checkoutCateringPriceMinimum
    ) {
      checkMinimumNotReachedEvent(calculateCartTotal(), mParticle);
    }
  }, [
    mParticle,
    calculateCartTotal,
    cartEntries.length,
    checkoutCateringPriceMinimum,
    serviceMode,
  ]);
  const updateQuantity = useCallback(
    (cartId, quantity) => {
      if (quantity < 1) {
        return removeFromCart(cartId);
      }

      setCartEntries(prevCartEntries =>
        prevCartEntries.map(entry => {
          if (entry.cartId === cartId) {
            entry.quantity = quantity;
            entry.price = pricingFunction(entry, entry.quantity);
          }

          return entry;
        })
      );
    },
    [pricingFunction, removeFromCart]
  );

  // Updates a single CartEntry
  const updateCartEntry = useCallback(
    (newCartEntry, originalEntry) => {
      // update item in mParticle cart
      mParticle.logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry: originalEntry,
        previousCartEntries: [originalEntry],
      });
      mParticle.logAddOrRemoveFromCart({
        action: 'add',
        cartEntry: newCartEntry,
        previousCartEntries: [originalEntry],
      });

      // If the original entry has a reward applied but the new entry is a different item,
      // then remove the reward.
      const appliedReward = appliedLoyaltyRewards[originalEntry?.cartId];
      if (appliedReward && appliedReward.rewardBenefitId !== newCartEntry._id) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: appliedReward.rewardBenefitId,
            cartId: originalEntry.cartId,
          })
        );
      }

      setCartEntries(prevCartEntries => replaceEntryArrayItem(prevCartEntries, newCartEntry));
      toast.success(formatMessage({ id: 'updateCartSuccess' }, { itemName: newCartEntry.name }));
    },
    [appliedLoyaltyRewards, dispatch, formatMessage, mParticle]
  );

  // Updates multiple CartEntries at once
  const updateMultipleCartEntries = useCallback(
    (entriesToUpdate, allOriginalEntries) => {
      entriesToUpdate.forEach(newEntry =>
        updateCartEntry(
          newEntry,
          allOriginalEntries.find(originalEntry => originalEntry.cartId === newEntry.cartId)
        )
      );
    },
    [updateCartEntry]
  );

  // Adds a single cartEntry to the cart
  const addToCart = useCallback(
    (cartEntry, selectionAttrs) => {
      setCartEntries(prevCartEntries => {
        mParticle.logAddOrRemoveFromCart({
          action: 'add',
          cartEntry,
          previousCartEntries: prevCartEntries,
          selectionAttrs,
        });
        return prevCartEntries.concat([cartEntry]);
      });

      if (cartEntry.isDonation) {
        return;
      }
      toast.success(formatMessage({ id: 'addToCartSuccess' }, { itemName: cartEntry.name }));
    },
    [setCartEntries, mParticle, formatMessage]
  );

  // Adds multiple cart entries to the cart
  const addMultipleToCart = useCallback(
    (newCartEntries, eventAttrs) => {
      newCartEntries.forEach(entry => {
        addToCart(entry, eventAttrs);
      });
    },
    [addToCart]
  );

  // Adds or Updates CartEntries
  // Accepts CartEntries and will determain which ones are being updated and which ones are new using their cartIds
  const upsertCart = useCallback(
    (newCartEntries, eventAttrs) => {
      // If there are no items in the cart already just add all new entries to the cart
      if (!cartEntries.length) {
        addMultipleToCart(newCartEntries, eventAttrs);
        return;
      }

      const existingEntries = intersectionBy(newCartEntries, cartEntries, 'cartId');
      const newEntries = differenceBy(newCartEntries, cartEntries, 'cartId');

      addMultipleToCart(newEntries, eventAttrs);
      updateMultipleCartEntries(existingEntries, cartEntries);
    },
    [addMultipleToCart, cartEntries, updateMultipleCartEntries]
  );

  const logOrderLatencyDuration = useCallback(
    (actionType, remoteOrder) => {
      if (!['commit', 'price'].includes(actionType)) {
        return;
      }

      const { endMark, startMark } =
        actionType === 'price'
          ? { endMark: PerformanceMarks.PRICE_END, startMark: PerformanceMarks.PRICE_START }
          : { endMark: PerformanceMarks.COMMIT_END, startMark: PerformanceMarks.COMMIT_END };

      const measure = actionType === 'price' ? Measures.PRICE : Measures.COMMIT;

      const duration = getMeasureAndClearMarks(measure, startMark, endMark);

      if (duration !== undefined) {
        mParticle.logOrderLatencyEvent(remoteOrder, actionType, duration);
      }
    },
    [mParticle]
  );

  const swapItems = useCallback(
    (from, to, offer) => {
      setCartEntries(prevEntries => {
        // Root cart entry is needed for appliedOffers
        // Child cart entry is needed for swaps objects
        const { rootEntry, childEntry } = findEntriesByCmsId(prevEntries, from);
        let entriesToReturn = prevEntries;

        if (rootEntry) {
          const oldEntry = rootEntry;
          // Define price for item
          const oldEntryUnitaryPrice = oldEntry.price / oldEntry.quantity;
          const { type: swapType, offerId, offerType, cmsId } = offer;

          const entryToSwap = {
            ...createCartEntry({
              item: to,
              price: oldEntry.children.length ? 0 : oldEntryUnitaryPrice,
              quantity: 1,
            }),
            sanityId: to._id,
          };

          let offerToApply = {
            id: offerId,
            cartId: oldEntry.children.length ? oldEntry.cartId : entryToSwap.cartId,
            type: offerType,
            swap: {
              cartId: entryToSwap.cartId,
              from,
              to: to._id,
              swapType,
            },
            cmsId,
          };

          // This covers the case when a cartEntry has more than one item and swap is selected
          // updating price and quantity to the cartEntry that was swapped
          if (oldEntry.quantity > 1) {
            oldEntry.quantity--;
            oldEntry.price = oldEntryUnitaryPrice * oldEntry.quantity;

            // If cartEntry has children, we keep a copy of the entry without
            // the swap applied to concat to the entries array after applying the swap
            if (oldEntry.children.length) {
              const splittedItem = cloneDeep(oldEntry);
              splittedItem.cartId = uuidv4();
              offerToApply.cartId = oldEntry.cartId;
              // Creating new cart entry with swap applied to replace at root level
              // having quantity and price correct for a swapped item
              const newRoot = {
                ...oldEntry,
                children: replaceEntry(oldEntry.children, from, { ...entryToSwap, price: 0 }),
                price: oldEntryUnitaryPrice,
                quantity: 1,
              };

              entriesToReturn = replaceEntry(prevEntries, oldEntry._id, newRoot).concat(
                splittedItem
              );
            } else {
              entriesToReturn = prevEntries.concat(entryToSwap);
            }
          } else {
            entriesToReturn = replaceEntry(prevEntries, from, entryToSwap);
          }

          dispatch(actions.loyalty.applyOffer(offerToApply));

          mParticle.logEvent(CustomEventNames.SWAP_OFFER_USED, EventTypes.Other, {
            'Original Item Name': childEntry.name,
            'Original Item Sanity ID': from,
            'Swapped Item Name': to.name?.locale,
            'Swapped Item Sanity ID': to._id,
            'Systemwide Offer ID': offer.offerId,
          });
        }

        return entriesToReturn;
      });
    },
    [setCartEntries, dispatch, mParticle]
  );

  const queryOrder = useCallback(
    async rbiOrderId => {
      try {
        const { data, errors } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: GetOrderDocument,
          variables: {
            rbiOrderId,
          },
        });

        if (errors) {
          throw errors;
        }

        return data?.order;
      } catch (error) {
        logger.error({ error, message: 'Error querying order' });
        throw error;
      }
    },
    [apolloClient, logger]
  );

  // commit was being fired twice, which was firing onCommitSuccess twice
  // the lastPurchaseOrderRId checks if the same order is being committed twice
  // where we set .current = rbiOrderID is on the first run, so the second run will return early
  const lastPurchaseOrderId = useRef();
  const onCommitSuccess = useCallback(
    async remoteOrder => {
      if (lastPurchaseOrderId.current === remoteOrder.rbiOrderId) {
        return;
      }
      if (remoteOrder?.cart?.serviceMode === ServiceMode.CURBSIDE) {
        setCurbsidePickupOrderId('');
        setCurbsidePickupOrderTimePlaced('');
      }
      lastPurchaseOrderId.current = remoteOrder.rbiOrderId;
      emptyCart();
      const cartEntriesData = offers.selectedOfferCartEntry
        ? [...cartEntries, offers.selectedOfferCartEntry]
        : cartEntries;
      const quotedFeeCents = remoteOrder.delivery?.quotedFeeCents || 0;
      const deliveryFeeCents = remoteOrder.delivery?.feeCents || 0;
      const deliveryFeeDiscountCents = remoteOrder.delivery?.feeDiscountCents || 0;
      const deliveryGeographicalFeeCents = remoteOrder.delivery?.geographicalFeeCents || 0;
      const deliveryServiceFeeCents = remoteOrder.delivery?.serviceFeeCents || 0;
      const deliverySmallCartFeeCents = remoteOrder.delivery?.smallCartFeeCents || 0;
      const baseDeliveryFeeCents = remoteOrder.delivery?.baseDeliveryFeeCents || 0;
      const deliverySurchargeFeeCents = remoteOrder.delivery?.deliverySurchargeFeeCents || 0;
      mParticle.logPurchase(cartEntriesData, store, serviceMode, remoteOrder, {
        currencyCode,
        quotedFeeCents,
        baseDeliveryFeeCents,
        totalDeliveryOrderFeesCents:
          baseDeliveryFeeCents +
          deliverySurchargeFeeCents +
          deliveryServiceFeeCents +
          deliverySmallCartFeeCents +
          deliveryGeographicalFeeCents -
          deliveryFeeDiscountCents,
        deliveryFeeCents:
          deliveryFeeCents -
          deliveryFeeDiscountCents -
          deliveryGeographicalFeeCents -
          deliveryServiceFeeCents -
          deliverySmallCartFeeCents,
        deliverySurchargeFeeCents,
        deliveryFeeDiscountCents,
        deliveryGeographicalFeeCents,
        deliveryServiceFeeCents,
        deliverySmallCartFeeCents,
        fireOrderInMinutes: Math.round(fireOrderIn / 60),
      });
      setServerOrder(remoteOrder);

      try {
        await Promise.all([
          selectedOffer ? onConfirmRedemption(selectedOffer) : Promise.resolve(),
          // refresh the user's payment methods stored
          // in state, including gift cards
          getPaymentMethods(),

          // refresh user's active loyalty card
          refetchActiveCard(),
          // refresh user's active loyalty card transactions
          refetchTransactions ? refetchActiveCardTransactions() : Promise.resolve(),
          // refresh last order for delivery banner
          deliveryBannerPolling ? refetchGetUserOrders() : Promise.resolve(),
          // refresh loyalty user points and recent transactions
          loyaltyUserId ? refetchLoyaltyUser() : Promise.resolve(),
          loyaltyUserId ? refetchLoyaltyUserTransaction() : Promise.resolve(),
          // refetch loyalty rewards
          dispatch(actions.loyalty.setShouldRefetchRewards(true)),
        ]);
      } catch (error) {
        logger.error({ error, message: 'Error after commit success' });
      }
    },
    [
      emptyCart,
      offers.selectedOfferCartEntry,
      cartEntries,
      mParticle,
      store,
      serviceMode,
      currencyCode,
      fireOrderIn,
      selectedOffer,
      onConfirmRedemption,
      getPaymentMethods,
      refetchActiveCard,
      refetchActiveCardTransactions,
      deliveryBannerPolling,
      refetchGetUserOrders,
      loyaltyUserId,
      refetchLoyaltyUser,
      refetchLoyaltyUserTransaction,
      dispatch,
      logger,
    ]
  );

  const price = useCallback(
    async paymentMethod => {
      setMark(PerformanceMarks.PRICE_START);

      const pollForPrice = async orderId => {
        if (!location.pathname.startsWith(routes.cart)) {
          clearTimeout(refPriceOrderTimeout.current);

          return Promise.resolve(null);
        }

        const remoteOrder = await queryOrder(orderId);

        const success = orderPollSuccessful({
          deliverySuccessStatus: DeliveryStatus.QUOTE_SUCCESSFUL,
          isDelivery,
          order: remoteOrder,
          orderSuccessStatus: OrderStatus.PRICE_SUCCESSFUL,
        });
        const failure = orderPollFailure({
          deliveryFailureStatus: [DeliveryStatus.QUOTE_ERROR, DeliveryStatus.QUOTE_UNAVAILABLE],
          isDelivery,
          order: remoteOrder,
          orderFailureStatus: OrderStatus.PRICE_ERROR,
        });

        if (success || failure) {
          setMark(PerformanceMarks.PRICE_END);

          logOrderLatencyDuration('price', remoteOrder);
        }

        if (success) {
          await offers.validateOfferRedeemable({
            selectedOffer: offers.selectedOffer,
            rbiOrderId: remoteOrder.rbiOrderId,
          });

          setServerOrder(remoteOrder);

          if (isDelivery) {
            const otherDiscountAmount = computeOtherDiscountAmount(remoteOrder.cart.discounts);
            updateTipAmount({ subTotalCents: remoteOrder.cart.subTotalCents, otherDiscountAmount });
          }

          return remoteOrder.status;
        } else if (failure) {
          setServerOrder(remoteOrder || {});

          throw new Error(OrderStatus.PRICE_ERROR);
        } else {
          return new Promise(resolve => {
            refPriceOrderTimeout.current = setTimeout(
              () => resolve(pollForPrice(remoteOrder.rbiOrderId)),
              1000
            );
          });
        }
      };

      // create offer cart item with updated plus
      const cartEntriesWithSelectedOffer = offers.selectedOfferCartEntry
        ? [
            {
              ...offers.selectedOfferCartEntry,
              price: offers.selectedOfferPrice,
              offerVendorConfigs: offers.selectedOffer.vendorConfigs,
              type: `Offer${offers.selectedOfferCartEntry.type}`,
            },
          ].concat(cartEntries)
        : cartEntries;

      const priceInCents = calculateCartTotal(cartEntriesWithSelectedOffer);
      const storeAddress = buildStoreAddress(store);
      const remappedCartEntries = remappedCartForBackEnd(cartEntriesWithSelectedOffer);

      const orderInput = {
        calculateCartTotal,
        cartEntries: cartEntriesWithSelectedOffer,
        cartVersion: CART_VERSION,
        customerLocale,
        customerName: null,
        deliveryAddress,
        orderPhoneNumber,
        loyaltyBarcode: barcode,
        paymentMethod,
        redeemReward,
        serviceMode,
        store,
      };

      const deliveryInput = buildPriceDeliveryInput(orderInput, auth.user, quoteId);

      try {
        const { data, errors } = await executePriceOrderMutation({
          variables: {
            delivery: deliveryInput,
            input: {
              ...orderInput,
              brand: brand().toUpperCase(),
              storeAddress,
              cartEntries: remappedCartEntries,
              platform: platform(),
              posVendor: store.pos.vendor,
              requestedAmountCents: Math.round(priceInCents),
              storeId: store.number,
              storePosId: store.posRestaurantId,
              appliedOffers,
              vatNumber: store.vatNumber,
            },
          },
        });

        if (errors) {
          throw errors;
        }

        const remoteOrder = data.priceOrder;

        if (!remoteOrder) {
          throw new Error(OrderStatus.PRICE_ERROR);
        }
        return pollForPrice(remoteOrder.rbiOrderId);
      } catch (error) {
        logger.error({ error, message: 'Error pricing order' });
        throw error;
      }
    },
    [
      offers,
      cartEntries,
      calculateCartTotal,
      store,
      CART_VERSION,
      customerLocale,
      deliveryAddress,
      orderPhoneNumber,
      barcode,
      serviceMode,
      auth.user,
      quoteId,
      location.pathname,
      queryOrder,
      isDelivery,
      logOrderLatencyDuration,
      updateTipAmount,
      executePriceOrderMutation,
      appliedOffers,
      logger,
    ]
  );

  const getAndRefreshServerOrder = useCallback(
    async id => {
      try {
        const order = await queryOrder(id);
        setServerOrder(order || {});
        return order;
      } catch (error) {
        logger.error({ error, message: 'Error refreshing order' });
      }
    },
    [queryOrder, logger]
  );

  const fireOrderInXSeconds = useCallback(
    async ({ rbiOrderId, timeInSeconds }) => {
      try {
        const { data, errors } = await executeUpdateOrderMutation({
          variables: {
            input: {
              fireOrderIn: timeInSeconds,
              rbiOrderId,
            },
          },
        });

        if (errors) {
          throw errors;
        }

        return getAndRefreshServerOrder(data?.updateOrder?.rbiOrderId);
      } catch (error) {
        logger.error({ error, message: 'Error updating order.' });
        throw error;
      }
    },
    [executeUpdateOrderMutation, getAndRefreshServerOrder, logger]
  );

  const clearServerOrder = () => {
    setServerOrder({});
  };

  // make sure we persist everything!
  useEffect(() => {
    LocalStorage.setItem(StorageKeys.ORDER, {
      cartEntries,
      cartIdEditing,
      cartVersion: CART_VERSION,
      cateringPickupDateTime,
      deliveryAddress,
      deliveryInstructions,
      quoteId,
      orderPhoneNumber,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
    });
  }, [
    cartEntries,
    cateringPickupDateTime,
    serviceMode,
    deliveryInstructions,
    orderPhoneNumber,
    deliveryAddress,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
    cartIdEditing,
    CART_VERSION,
    quoteId,
  ]);

  // Configure the logger to hold some information
  useEffect(() => {
    const extras = {
      cartEntries: JSON.stringify(cartEntries, null, 4),
      serviceMode,
    };

    if (serverOrder.rbiOrderId) {
      DatadogLogger.addContext('transaction_id', serverOrder.rbiOrderId);
    }

    decorateLogger({ ...extras, transactionId: serverOrder.rbiOrderId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartEntries, serverOrder.rbiOrderId, serviceMode, store]);

  const isCartEntryInSwaps = useCallback(
    cartEntry =>
      appliedOffers.some(offer =>
        cartEntry?.children?.length
          ? offer.cartId === cartEntry.cartId
          : offer?.swap?.cartId === cartEntry.cartId
      ),
    [appliedOffers]
  );

  const getItemPriceForRepricing = useCallback(
    (entry, parentEntry, mainCombo) => {
      const isComboSlot = parentEntry?.type === CartEntryType.comboSlot;
      const isDelta = premiumComboSlotPricingMethod === EnablePremiumComboSlotsVariations.DELTA;

      if (!isComboSlot) {
        return pricingFunction(entry, entry.quantity);
      }

      // If pricing method is delta, price should not be calculated
      return isDelta
        ? entry.price
        : priceForItemInComboSlotSelection({
            combo: mainCombo,
            comboSlot: parentEntry,
            selectedItem: entry,
          });
    },
    [premiumComboSlotPricingMethod, priceForItemInComboSlotSelection, pricingFunction]
  );

  // When repricing cart entry, we need a record of parent and main combo in order to price combo item
  const repriceCartEntriesHelper = useCallback(
    (entries, parentEntry, mainCombo) =>
      entries.map(entry => {
        const hasPrice = entry.price !== undefined;
        const isItem = entry.type === CartEntryType.item;
        const isCombo = entry.type === CartEntryType.combo;
        const isSwap = isCartEntryInSwaps(entry);

        if (isItem) {
          return {
            ...entry,
            // prices are not defined on main items in combos, we need to preserve this
            ...(!isSwap &&
              hasPrice && { price: getItemPriceForRepricing(entry, parentEntry, mainCombo) }),
            children: (entry.children || []).map(itemOption => ({
              ...itemOption,
              children: (itemOption.children || []).map(modifier => ({
                ...modifier,
                price: priceForItemOptionModifier({ item: entry, itemOption, modifier }),
              })),
            })),
          };
        }
        return {
          ...entry,
          ...(!isSwap && isCombo && hasPrice && { price: pricingFunction(entry, entry.quantity) }),
          children: repriceCartEntriesHelper(entry.children, entry, isCombo ? entry : mainCombo),
        };
      }),
    [isCartEntryInSwaps, pricingFunction, getItemPriceForRepricing, priceForItemOptionModifier]
  );

  const repriceCartEntries = useCallback(
    (entries, parentEntry, mainCombo) => {
      const newEntries = repriceCartEntriesHelper(entries, parentEntry, mainCombo);
      // If newEntries is structurally equal to entries, then return original object
      // to avoid unnecessary re-renders. This is a fix to priceOrder mutation being called twice.
      return isEqual(entries, newEntries) ? entries : newEntries;
    },
    [repriceCartEntriesHelper]
  );

  // recursively reprice cartEntries when prices change
  useEffect(() => {
    if ((enableMenuServiceData && storeMenuLoading) || !prices) {
      return;
    }

    setCartEntries(prevEntries => repriceCartEntries(prevEntries));
    // adding repriceCartEntries to the deps array crashes the browser
    // because of its dependency on pricingFunction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, storeMenuLoading]);

  // show error cart price limit modal
  const isCatering = isCateringOrder(serviceMode);
  useAlertOrderLimit({
    calculateCartTotal,
    checkoutPriceLimit,
    checkoutCateringPriceLimit,
    alertOrderLimit,
    isCatering,
    setCartPriceLimitExceeded,
  });
  useAlertOrderDeliveryMinimum({
    cartSubtotal:
      serverOrder?.cart?.subTotalCents ??
      // subtotal without discounts
      computeCartTotal(offers, cartEntries, {
        loyaltyEnabled,
        appliedLoyaltyRewards,
      }),
    checkoutDeliveryPriceMinimum,
    isDelivery,
    setCartPriceTooLow,
  });
  useAlertOrderCateringMinimum({
    calculateCartTotal,
    checkoutCateringPriceMinimum,
    isCatering,
    setCartCateringPriceTooLow,
  });

  const handleReorder = useHandleReorder({
    addToCart,
    navigate,
    setPendingReorder,
    setReordering,
    storeHasSelection: isStoreOpenAndAvailable,
    setUnavailableCartEntries,
    setReorderedOrderId,
    cartEntries,
  });

  const isCartTotalGreaterThanDiscountOfferValue = useCallback(() => {
    const total = calculateCartTotal();
    let discountValue = 0;
    if (selectedOffer) {
      const selectedDiscountOfferType = selectedOffer?.option?.discountType;
      const selectedDiscountOfferValue = selectedOffer?.option?.discountValue || 0;

      discountValue =
        selectedDiscountOfferType === OfferDiscountTypes.AMOUNT ? selectedDiscountOfferValue : 0;
    } else if (discountAppliedCmsOffers?.length) {
      discountValue = discountAppliedCmsOffers.reduce((acc, loyaltyCmsOffer) => {
        const discountIncentive = loyaltyCmsOffer?.incentives?.[0];
        const offerDiscountValue =
          discountIncentive?.discountType === OfferDiscountTypes.AMOUNT
            ? discountIncentive?.discountValue || 0
            : 0;

        return acc + offerDiscountValue;
      }, 0);
    }

    return discountValue > total / 100;
  }, [calculateCartTotal, discountAppliedCmsOffers, selectedOffer]);
  const offerCartEntry = offers.selectedOfferCartEntry || null;
  const cartPreviewEntries = offerCartEntry ? [offerCartEntry, ...cartEntries] : cartEntries;
  const isCartEmpty = !cartPreviewEntries.length;
  const canUserCheckout = !isCartEmpty && !cartPriceLimitExceeded;
  const numCartPreviewEntries = useMemo(
    () => (cartPreviewEntries || []).reduce((accum, entry) => accum + entry.quantity ?? 1, 0),
    [cartPreviewEntries]
  );

  const selectStore = useCallback(
    async (newStore, callback, requestedServiceMode) => {
      setFetchingPosData(true);
      const selectedStorePrices = await getPosData({
        restaurantPosDataId: newStore.restaurantPosData?._id || '',
        storeNumber: newStore.number,
      });

      const unavailableItems = getUnavailableCartEntries(
        cartEntries || [],
        newStore,
        selectedStorePrices?.posData || prices,
        requestedServiceMode
      );

      const selectNewStoreCallback = () => {
        if (unavailableItems.length) {
          removeAllFromCart(unavailableItems);
        }
        callback();
      };

      const updateCallback = async () => {
        await selectNewStore({
          sanityStore: newStore,
          hasCartItems: !isCartEmpty,
          unavailableCartEntries: unavailableItems,
          callback: selectNewStoreCallback,
          requestedServiceMode,
          selectedOffer,
        });
        setFetchingPosData(false);
      };

      if (isDelivery) {
        updateCallback();
      } else {
        updateUserStoreWithCallback(newStore, updateCallback);
      }
    },
    [
      cartEntries,
      getPosData,
      isCartEmpty,
      isDelivery,
      prices,
      removeAllFromCart,
      selectNewStore,
      selectedOffer,
      updateUserStoreWithCallback,
      setFetchingPosData,
    ]
  );

  return (
    <OrderContext.Provider
      value={{
        // state
        cartEntries,
        numCartPreviewEntries,
        cartVersion: CART_VERSION,
        unavailableCartEntries,
        serviceMode,
        serverOrder,
        cartPriceLimitExceeded,
        cartPriceTooLow,
        cartCateringPriceTooLow,
        cartHasRewardEligibleItem,
        // Catering
        cateringPickupDateTime,
        setOrderCateringPickupDateTime,
        // Cart
        checkoutPriceLimit,
        checkoutDeliveryPriceMinimum,
        checkoutCateringPriceLimit,
        checkoutCateringPriceMinimum,
        alertOrderLimit,
        alertOrderDeliveryMinimum,
        alertOrderCateringMinimum,
        confirmRemoveFromCart,
        removeFromCart,
        removeAllFromCart,
        setUnavailableCartEntries,
        editCart,
        cartIdEditing,
        getCurrentCartEntry,
        addToCart,
        upsertCart,
        updateCartEntry,
        updateQuantity,
        calculateCartTotal,
        calculateCartTotalWithDiscount,
        calculateCartTotalWithoutOffers,
        emptyCart,
        tipAmount,
        setTipAmount,
        updateTipAmount,
        tipSelection,
        setTipSelection,
        verifyCartVersion,
        repriceCartEntries,
        shouldShowTipPercentage,
        isCartTotalGreaterThanDiscountOfferValue,
        isCartEmpty,
        cartPreviewEntries,
        canUserCheckout,
        swapItems,
        isCartEntryInSwaps,
        // Order
        selectServiceMode,
        clearServerOrder,
        selectStore,
        fetchingPosData,
        clearCartStoreServiceModeTimeout,
        logCartStoreAndTimeout,
        deliveryAddress,
        setDeliveryAddress,
        deliveryInstructions,
        setDeliveryInstructions,
        orderPhoneNumber,
        setOrderPhoneNumber,
        isCatering,
        isDelivery,
        fireOrderIn,
        setFireOrderIn,
        logOrderLatencyDuration,
        onCommitSuccess,
        tipPercentThresholdCents,
        curbsidePickupOrderId,
        updateShouldSaveDeliveryAddress,
        setCurbsidePickupOrderId,
        curbsidePickupOrderTimePlaced,
        setCurbsidePickupOrderTimePlaced,
        setQuoteId,
        quoteId,
        // Server Interactions
        price,
        query: getAndRefreshServerOrder,
        fireOrderInXSeconds,
        reorder: {
          handleReorder,
          reordering,
          pendingReorder,
          setReordering,
          setPendingReorder,
          reorderedOrderId,
        },
        recent: {
          setPendingRecentItem,
          pendingRecentItem,
          pendingRecentItemNeedsReprice,
          setPendingRecentItemNeedsReprice,
        },
        isPricingOrder,
        setIsPricingOrder,
      }}
    >
      {props.children}

      <OrderLimitDialog
        body={orderLimitMessage(checkoutPriceLimit, checkoutCateringPriceLimit, isCatering)}
        heading={formatMessage({ id: 'orderLimitHeading' })}
      />
      <RemoveItemDialog
        body={removeItemMessage(itemToRm)}
        heading={formatMessage({ id: 'removeItem' })}
      />
      <ServiceModeDialog
        body={formatMessage({ id: 'changeStoreMessage' })}
        heading={formatMessage({ id: 'changeServiceMode' })}
      />
    </OrderContext.Provider>
  );
}

export default OrderContext.Consumer;
