import React, { useEffect, useMemo } from 'react';

import { ApolloProvider } from '@apollo/react-hooks';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useAmplitudeContext } from 'state/amplitude';
import { useLocale } from 'state/intl';
import { useGatewayFlags } from 'state/launchdarkly';
import { useLogger } from 'state/logger';

import { getClient } from './client';
import { withI18nContext } from './links/with-i18n-context';
import { withLogger } from './links/with-logger';
import { withSessionId } from './links/with-session-id';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  const { language, region } = useLocale();
  const gatewayFlags = useGatewayFlags();
  const client = useMemo(() => getClient(language, region), [gatewayFlags]); // eslint-disable-line react-hooks/exhaustive-deps
  const logger = useLogger();
  const amplitude = useAmplitudeContext();

  // update stateful links
  useEffect(() => {
    withI18nContext.setLocale(language, region);
  }, [client, language, region]);

  useEffect(() => {
    withSessionId.setSessionId(amplitude.sessionId);
  }, [amplitude.sessionId]);

  useEffect(() => {
    withLogger.setLogger(logger);
  }, [logger]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
