import styled from 'styled-components';

export const WordmarkSocialIconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 1.125rem 0;

  :first-child {
    padding-top: 0.75rem;
  }

  :last-child {
    padding-bottom: 0;
  }
`;
