import React, { FC } from 'react';

import styled from 'styled-components';

import { Row } from './shared';
import { IFooterProps } from './types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = styled.footer`
  font-family: ${Styles.fontFamily.base};
  display: none;
  background-color: ${Styles.color.black};
  color: ${Styles.color.white};
  padding: 3rem 2rem 0 6rem;
  width: 100%;

  ${Styles.desktop`
    display: block;
  `}
`;

const SanityFooterContainer = styled(FooterContainer)`
  padding: 1rem 6rem;
`;

const FooterContent: FC<IFooterProps> = props => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();

  return featureFooterLoading ? null : (
    <SanityFooterContainer {...props} role="contentinfo">
      {featureFooter?.rows?.map((row, index) => row && <Row key={row._key ?? index} {...row} />)}
    </SanityFooterContainer>
  );
};

export default FooterContent;
