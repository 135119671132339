import { useCallback, useEffect, useMemo, useState } from 'react';

import { merge } from 'lodash-es';

import { IOffer } from '@rbi-ctg/menu';
import { IEvaluationFeedback } from '@rbi-ctg/offers';
import { useEvaluateUserOffersLazyQuery, useRedeemOfferMutation } from 'generated/rbi-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';
import { Query } from 'state/network/hook';
import { ICartInput, buildCartInput } from 'utils/cart';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { ILogger } from 'utils/logger';
import { allowsUnauthenticatedRedemption, redeemedOnTime } from 'utils/offers';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { ClearSelectedOffer } from './types';

export enum RedemptionMethod {
  mobile = 'mobile',
  restaurant = 'restaurant',
}

export enum Status {
  Login = 'Log In',
}

export interface IUseOfferRedemptionProps {
  logger: ILogger;
  mParticle: IMParticleCtx;
  query: Query;
  user: null | UserDetails;
  clearSelectedOffer: ClearSelectedOffer;
  upsellOfferId: string | null;
  decorateLogger<O extends object>(object: O): void;
  queryRedeemableOffers: () => void;
  redeemUnauthenticatedOffer(couponId: string): void;
}

// names of the redemption methods sent
// as an attribute on mParticle events
const RedemptionMethodNames = {
  [RedemptionMethod.mobile]: 'Mobile Order',
  [RedemptionMethod.restaurant]: 'Restaurant Order',
};

// name of mParticle event for redemption method
const RedemptionMethodEventNames = {
  [RedemptionMethod.mobile]: CustomEventNames?.OFFER_SELECTED_MOBILE,
  [RedemptionMethod.restaurant]: CustomEventNames?.OFFER_SELECTED_RESTAURANT,
};

interface IPersistedState {
  redemptionMethod?: null | RedemptionMethod;
}

const getPreloadedState = () =>
  LocalStorage.getItem<IPersistedState>(StorageKeys.OFFER_REDEMPTION) || {};

export const CART_MISSING_REQS = '#cart - missing required plus';

export default function useOfferRedemption({
  clearSelectedOffer,
  decorateLogger,
  logger,
  mParticle,
  queryRedeemableOffers,
  redeemUnauthenticatedOffer,
  user,
  upsellOfferId,
}: IUseOfferRedemptionProps) {
  const { redemptionMethod: preloadedRedemptionMethod = null } = useMemo(getPreloadedState, []);
  const [redemptionMethod, setRedemptionMethod] = useState<RedemptionMethod | null>(
    preloadedRedemptionMethod
  );
  const [redeemedOffer, setRedeemedOffer] = useState<IOffer | null>(null);
  const [offerValidationErrors, setOfferValidationErrors] = useState<IEvaluationFeedback[]>([]);
  const [evaluateUserOfferQuery, { data, loading }] = useEvaluateUserOffersLazyQuery();
  const { logEvent, logRBIEvent } = mParticle;

  const selectRedemptionMethod = useCallback(
    (selectedRedemptionMethod: RedemptionMethod) => {
      setRedemptionMethod(selectedRedemptionMethod);
      logEvent(RedemptionMethodEventNames[selectedRedemptionMethod], EventTypes.Other);
    },
    [logEvent]
  );

  const onSelectRestaurantRedemption = useCallback(() => {
    selectRedemptionMethod(RedemptionMethod.restaurant);
  }, [selectRedemptionMethod]);

  const onSelectMobileRedemption = useCallback(() => {
    selectRedemptionMethod(RedemptionMethod.mobile);
  }, [selectRedemptionMethod]);

  const onCancelRedemption = useCallback(
    (isLockedOffer = false) => {
      if (!isLockedOffer) {
        clearSelectedOffer();
      }
      setRedemptionMethod(null);
      logEvent(CustomEventNames.OFFER_REDEMPTION_CANCELLED, EventTypes.Other);
    },
    [clearSelectedOffer, logEvent]
  );

  const [redeemOfferMutation] = useRedeemOfferMutation();

  // the backend is responsible for tracking redemption updates
  // on successful commit, however for restaurant redemptions
  // we need to inform the BE that the user has redeemed the offer.
  // for "unauthenticated" offers and mobile orders, we only need to log the
  // event to mParticle and update state
  const onConfirmRedemption = useCallback(
    async (selectedOffer: IOffer, isLockedOffer: boolean = false): Promise<void> => {
      const selectedOfferId = selectedOffer._id;
      const isUnauthenticatedOffer = allowsUnauthenticatedRedemption(selectedOffer.ruleSet);
      const shortCode = selectedOffer.shortCode;
      const uiPattern = selectedOffer.uiPattern;
      const redemptionMethodName = RedemptionMethodNames[redemptionMethod!];
      const offerPrice = selectedOffer.offerPrice ? selectedOffer.offerPrice / 100 : 0.0;
      const offerName = getFirstStringInLocaleBlockContent(selectedOffer.name);
      const tags = selectedOffer?.offerTags?.map(v => v.value).join(',') || '';
      const isUpsellOffer = selectedOfferId === upsellOfferId;
      logEvent(CustomEventNames.OFFER_REDEMPTION_CONFIRMED, EventTypes.Other, {
        'Sanity ID': selectedOfferId,
        'Offer Order Type': redemptionMethodName,
        'Short Code': shortCode,
        'Offer Price': offerPrice,
        'Offer Name': offerName,
        'UI Pattern': uiPattern,
        Tags: tags,
        'Is Upsell': isUpsellOffer,
      });
      logRBIEvent({
        name: CustomEventNames.OFFER_ADDED_TO_ORDER,
        type: EventTypes.Other,
        attributes: {
          engineId: '',
          sanityId: selectedOfferId,
          name: offerName,
          redemptionMode: redemptionMethod === RedemptionMethod.mobile ? 'Online' : 'In-Store',
        },
      });
      if (!isLockedOffer) {
        clearSelectedOffer({ doLog: false });
      }
      setRedemptionMethod(null);
      setOfferValidationErrors([]);

      if (isUnauthenticatedOffer && !user) {
        redeemUnauthenticatedOffer(selectedOfferId);
        return Promise.resolve();
      }

      if (redemptionMethod === RedemptionMethod.restaurant) {
        try {
          await redeemOfferMutation({
            variables: {
              couponId: selectedOfferId,
            },
          });
        } catch (error) {
          logger.error({
            error,
            message: 'Add Redeemed Offer error',
          });
          // @todo throw error on redeem offer failure
        }
      }

      return queryRedeemableOffers();
    },
    [
      redeemOfferMutation,
      clearSelectedOffer,
      logEvent,
      logger,
      queryRedeemableOffers,
      redeemUnauthenticatedOffer,
      redemptionMethod,
      user,
      upsellOfferId,
    ]
  );

  // checks whether an offer can be redeemed,
  // optionally with a cart and rbiOrderId
  const validateOfferRedeemable = useCallback(
    async ({
      cart,
      rbiOrderId,
      selectedOffer,
    }: {
      cart?: ICartInput;
      rbiOrderId?: string;
      selectedOffer: IOffer;
    }): Promise<void> => {
      if (!selectedOffer && !rbiOrderId) {
        return;
      }

      const couponIds = selectedOffer ? [selectedOffer._id] : [];
      const cartInput = cart && buildCartInput(cart);

      const queryInput = rbiOrderId ? { couponIds, rbiOrderId } : { cart: cartInput, couponIds };
      await evaluateUserOfferQuery({
        // @ts-ignore
        variables: merge(
          {
            redeemedOn: redeemedOnTime(),
          },
          queryInput
        ),
      });
    },
    [evaluateUserOfferQuery]
  );

  useEffectOnUpdates(() => {
    // avoid set the default value when the request is loading
    if (loading) {
      return;
    }

    const evaluationData = data ? data.evaluateUserOffers.offersFeedback : [];

    const feedbackErrors = evaluationData.reduce<IEvaluationFeedback[]>((acc, feedbackEntry) => {
      if (!feedbackEntry) {
        return acc;
      }

      const { redemptionEligibility } = feedbackEntry;

      if (redemptionEligibility.isRedeemable) {
        return acc;
      }

      return acc.concat(redemptionEligibility.evaluationFeedback as IEvaluationFeedback[]);
    }, []);

    setOfferValidationErrors(feedbackErrors);
  }, [data, loading]);

  useEffect(() => {
    LocalStorage.setItem(StorageKeys.OFFER_REDEMPTION, {
      redemptionMethod,
    });
    decorateLogger({
      selectedOfferRedemptionMethod: redemptionMethod,
    });
  }, [decorateLogger, redemptionMethod]);

  return {
    offerValidationErrors,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    setOfferValidationErrors,
    setRedemptionMethod,
    validateOfferRedeemable,
  };
}
