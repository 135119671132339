// This file is generated via scripts/build-static-config.js
// Do not modify directly as changes will be overwritten in CI

export const CONFIGURATION = {
  country: 'ca',
  brand: 'plk',
  env: 'prod',
  graphqlEnv: 'prod',
  graphqlGatewayEnv: '',
  sanityEnv: 'prod',
  platform: 'web',
  commitRef: 'a7c732d10ad0b14189a7ef69cbfa38194686c656',
  releaseTimestamp: '1690835700',
  sanityDataset: '',
  sanityProjectId: 'czqk28jt',
  appFlowBuildId: '',
  adyen: {
    clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
    environment: 'live-us',
    urlPrefix: 'https://checkoutshopper-live.adyen.com',
  },
  apiKeys: {
    amplitude: 'c52e0034bd5b3e0784f0694f7677d548',
    branch: 'key_live_bm8PtmBpo9JHbJfbEwMJljnmsukHymPZ',
    braze: '21232095-1b77-412e-9a0c-d9aa020fba03',
    bringg: '',
    googleMaps: 'AIzaSyDq2VB2WonHKXkJNKgK2cXT5bC9Tn3sBjU',
    googleAnalytics: '',
    tomtom: '',
    launchDarkly: '5f4d1823712dd3091d5f28d4',
    launchDarklyMobileKey: 'mob-70dc7d74-a49b-461d-bc43-177fb7279743',
    logRocket: 'mj7uvx/ctg-prod',
    mParticle: 'us1-7c99debb0efd144fb70a48e96944ca78',
    radar: '',
  },
  apple: {
    merchantId: 'merchant.com.adyen.native.plk.ca.prod',
    migrationMerchantId: 'merchant.com.plk.ca.native.orbital.prod',
    paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
    prepaidMerchantId: '',
    migrationPrepaidMerchantId: '',
  },
  aws: {
    gqlApiUrl: 'https://use1-prod-plk.rbictg.com/graphql',
    gqlGatewayApiUrl: 'https://use1-prod-plk-gateway.rbictg.com/graphql',
    region: 'us-east-1',
    userPoolClientId: '6jl47h927uikv6n1386j99fuo6',
    userPoolId: 'us-east-1_qIsU20KIm',
  },
  bringg: {
    region: '6',
  },
  datadog: {
    logs: {
      clientToken: 'pub0048f82c565e9a608141557a1ea1fdef',
      dataCenter: '',
    },
    rum: {
      applicationId: '2ce34ad0-4de5-4ea7-91fe-9fda95fe4c73',
      clientToken: 'pub0048f82c565e9a608141557a1ea1fdef',
      dataCenter: '',
    },
  },
  forter: {
    siteId: '305cb54b38d6',
  },
  google: {
    paymentsEnvironment: 'prod',
    paymentsGateway: 'adyen',
    paymentsGatewayMerchantId: {
      ca: 'RestaurantBrandsInternational',
      us: '',
    },
    migrationPaymentsGateway: 'chase',
    migrationPaymentsGatewayMerchantId: {
      ca: '521978',
      us: '',
    },
    paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
    prepaidPaymentsGateway: '',
    prepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    },
    migrationPrepaidPaymentsGateway: '',
    migrationPrepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    }
  },
  googleAdManager: {
    campaignId: '',
  },
  googleTagManager: {
    containerId: '',
  },
  oneTrust: {
    cookieCompliance: {
      consentCategoryBranch: 'C0004',
      consentCategoryBraze: 'C0002',
      consentCategoryMParticle: 'C0001',
      globalConsentState: 'Opt-In',
      scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      scriptId: '57e07c89-dd13-4e47-9be5-dad0a8e93b32',
    },
    deleteAccountUrl: '',
  },
  pypestream: {
    scriptUrl: '',
    appId: '',
  },
  restaurants: {
    displayEnv: 'prod',
    validMobileOrderingEnvs: ['alpha','beta','live'],
  },
  urls: {
    braze: '',
    firstData: 'https://api.payeezy.com',
    firstDataTh: '',
    webBaseUrl: '',
    thDigital: '',
  },
  tokenEx: {
    publicKey: '',
  },
  vrPayment: {
    braintreeTokenizationKey: '',
  },
  orbital: {
    baseUrl: 'https://safetechpageencryption.chasepaymentech.com',
    subscriberId: '750000001767',
    pieFormat: '04',
    mode: 'FPE',
  },
  timeFormat: '',
  dateFormat: '',
  appStoreDeeplink: 'https://popeyesca.app.link/Vo7oCxXgUzb',
};