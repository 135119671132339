import React from 'react';

import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Styles.desktop`
    padding: 2rem 0;
    border-bottom: 1px solid rgb(137, 108, 108, 0.1);

    :last-child {
      border-bottom: none;
    }
  `}

  > * {
    flex-basis: ${props => 100 / React.Children.count(props.children)}%;
  }
`;
