import ReactAppboy from 'utils/appboy';
import { Logger } from 'utils/logger';

export const setBrazeUserAttributes = (brazeAttributes: Record<string, string>): void => {
  const brazeUser = window.rbiAppboy ? window.rbiAppboy.getUser() : ReactAppboy;
  for (const key in brazeAttributes) {
    const value = brazeAttributes[key];
    try {
      switch (key) {
        case '$FirstName':
          brazeUser.setFirstName(value);
        case '$LastName':
          brazeUser.setLastName(value);
        case '$City':
          brazeUser.setHomeCity(value);
        case '$Mobile':
        case 'phoneNumber':
          brazeUser.setPhoneNumber(value);
        default:
          brazeUser.setCustomUserAttribute(key, value);
      }
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      Logger.error({ error, message: 'Error setting Braze User Attributes' });
    }
  }
};
