/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { REGIONS } from './types';

export enum PROD_SUPPORTED_REGIONS {
  CA = REGIONS.CA,
  CH = REGIONS.CH,
  ES = REGIONS.ES,
  FR = REGIONS.FR,
  KR = REGIONS.KR,
  US = REGIONS.US,
}

export const PROD_DEFAULT_REGION = PROD_SUPPORTED_REGIONS.US;
