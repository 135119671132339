/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { LOCALES } from './types';

export enum PROD_SUPPORTED_LOCALES {
  ca_es = LOCALES.ca_es,
  de_ch = LOCALES.de_ch,
  en_ca = LOCALES.en_ca,
  en_ch = LOCALES.en_ch,
  en_us = LOCALES.en_us,
  es_es = LOCALES.es_es,
  en_es = LOCALES.en_es,
  fr_ca = LOCALES.fr_ca,
  fr_ch = LOCALES.fr_ch,
  fr_fr = LOCALES.fr_fr,
  gl_es = LOCALES.gl_es,
  eu_es = LOCALES.eu_es,
  it_ch = LOCALES.it_ch,
  ko_kr = LOCALES.ko_kr,
  en_kr = LOCALES.en_kr,
}
