import { useEffect, useState } from 'react';

import LocalStorage from 'utils/cognito/storage';
import { getConfigValue } from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';

declare global {
  interface Window {
    OneTrust: any;
    OptanonWrapper: (event: any) => void;
  }
}

type ErrorState = ErrorEvent | null;

const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const ONE_TRUST_SDK_SCRIPT_ID = 'one-trust-cookie-consent-sdk';

type ConsentGroups = {
  C0001: number;
  C0002: number;
  C0003: number;
  C0004: number;
};

const getConsentGroups = (consentState: String[]) => {
  const groups: ConsentGroups = {
    C0001: 0,
    C0002: 0,
    C0003: 0,
    C0004: 0,
  };

  return Object.keys(groups).reduce((accumulator, group) => {
    if (consentState.includes(group)) {
      accumulator[group] = 1;
    }
    return accumulator;
  }, groups);
};

export const isOptIn = (consentState: ConsentGroups) => {
  // HACK: Our OneTrust consent groups are not configured correctly.
  // We know that C0002 gets set to `false` when denying tracking,
  // so use that to disable all cookies / tracking that is not strictly necessary
  //
  // CCPA is opt-in by default, e.g. users are tracked by default:
  // New users would have no stored consent state, so start our tracking services
  // or the user has explicitly accepted or chosen to close the dialog, so start our tracking services
  return consentState.C0002 === undefined || consentState.C0002;
};

/**
 * Loads one trust sdk cookie consent script
 */
export default function useOneTrustSdk() {
  const [loading, setLoading] = useState(!!window.OneTrust ? false : true);
  const [loaded, setLoaded] = useState(!!window.OneTrust ? true : false);
  const [error, setError] = useState<ErrorState>(null);
  const [otConsentGroups, setConsentGroups] = useState({} as ConsentGroups);
  const cookieCompliance = getConfigValue('oneTrust').cookieCompliance;
  const { scriptUrl, scriptId } = cookieCompliance;

  useEffect(() => {
    if (!IS_BROWSER || !scriptUrl || !scriptId) {
      return;
    }

    const existing = document.querySelectorAll(`script#${ONE_TRUST_SDK_SCRIPT_ID}`);
    const existingConsentGroups = LocalStorage.getItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS);

    if (existingConsentGroups) {
      setConsentGroups(existingConsentGroups);
    }

    if (existing.length > 0) {
      setLoading(false);
      setLoaded(true);
      return;
    }

    const handleLoad = () => {
      setLoading(false);
      setLoaded(true);

      // This code block is required by One Trust script which is called after the SDK is loaded
      window.OptanonWrapper = () => {
        window.OneTrust.OnConsentChanged((event: any) => {
          const consentState = event?.detail ?? [];
          const consentGroups = getConsentGroups(consentState);
          setConsentGroups(consentGroups);
          LocalStorage.setItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS, consentGroups);
          if (!isOptIn(consentGroups)) {
            window.location.reload();
          }
        });
      };
    };

    const handleError = (err: ErrorState) => {
      setLoading(false);
      setLoaded(false);
      setError(err);
    };

    const otSdkScript = document.createElement('script');
    // TODO: This will be updated to include live endpoints based on region.
    otSdkScript.setAttribute('src', scriptUrl);
    otSdkScript.setAttribute('data-domain-script', scriptId);
    otSdkScript.setAttribute('id', ONE_TRUST_SDK_SCRIPT_ID);
    otSdkScript.setAttribute('type', 'text/javascript');
    otSdkScript.setAttribute('charset', 'UTF-8');
    otSdkScript.setAttribute('crossOrigin', 'anonymous');
    otSdkScript.async = true;
    otSdkScript.addEventListener('load', handleLoad);
    otSdkScript.addEventListener('error', handleError);

    document.head.appendChild(otSdkScript);

    return () => {
      document.head.removeChild(otSdkScript);
      otSdkScript.removeEventListener('load', handleLoad);
      otSdkScript.removeEventListener('error', handleError);
    };
  }, []);

  return { loaded, loading, error, otConsentGroups, cookieComplianceConfig: cookieCompliance };
}
