import React, { useCallback, useEffect, useState } from 'react';

import { setOptOut } from 'state/amplitude/amplitude-package';
import { useBranch } from 'state/branch';
import { useBrazeContext } from 'state/braze';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import ReactAppboy from 'utils/appboy';
import LogRocketHelper from 'utils/logrocket';

import useOneTrustSdk, { isOptIn } from './use-one-trust-sdk';

enum OneTrustGlobalConsentState {
  OPT_OUT = 'Opt-Out',
  OPT_IN = 'Opt-In',
}

export const OneTrustCookieBanner = () => {
  const enableLogRocket = useFlag(LaunchDarklyFlag.ENABLE_LOGROCKET);
  const { initBraze } = useBrazeContext();
  const { initBranch } = useBranch();
  const { loaded: otSdkLoaded, otConsentGroups, cookieComplianceConfig } = useOneTrustSdk();

  const lrInstance = LogRocketHelper.getInstance().logrocket;

  const [servicesLoaded, setServiceLoaded] = useState(false);

  // Fucntion to initialize 3rd party services
  const initServicesWithCookies = useCallback(() => {
    if (enableLogRocket && !lrInstance) {
      LogRocketHelper.init();
    }
    if (!window.rbiAppboy) {
      initBraze();
    }
    if (!window.branch) {
      initBranch();
    }
  }, [enableLogRocket, initBranch, initBraze, lrInstance]);

  // loads 3rd party services once OneTrust sdk is loaded
  useEffect(() => {
    if (otConsentGroups && otSdkLoaded && !servicesLoaded) {
      // CCPA is opt-in by default, e.g. users are tracked by default:
      // New users would have no stored consent state, so start our tracking services
      // or the user has explicitly accepted or chosen to close the dialog, so start our tracking services
      if (isOptIn(otConsentGroups)) {
        initServicesWithCookies();
      } else {
        setOptOut(true); // Ensure Amplitude is not collecting any user data
      }
    }
  }, [initServicesWithCookies, otConsentGroups, otSdkLoaded, servicesLoaded]);

  const servicesInitCheck = useCallback(() => {
    const areServicesInitialized = !!window.branch && !!window.rbiAppboy;
    if (areServicesInitialized) {
      setServiceLoaded(true);
    }
    return areServicesInitialized;
  }, []);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(servicesInitCheck, 1000);
    if (servicesLoaded) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [servicesInitCheck, servicesLoaded]);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    const {
      globalConsentState,
      consentCategoryBraze,
      consentCategoryBranch,
    } = cookieComplianceConfig;

    // Do nothing if services have not loaded
    if (!servicesLoaded) {
      return;
    }

    // reads consent state for each consent category
    const mparticleConsentState = otConsentGroups[consentCategoryBraze]; // HACK: intentionally use Braze here as the mParticle consent state never changes
    const brazeConsentState = otConsentGroups[consentCategoryBraze];
    const branchConsentState = otConsentGroups[consentCategoryBranch];

    // OneTrust is very confusing. Opt-out state means the user is not blocking us from tracking/selling them so this is a double negation
    // More below....
    const isDoNotSellOptOutState = globalConsentState === OneTrustGlobalConsentState.OPT_OUT;

    // Making this variable to make it human readable since the double negation is clearly mind bending
    const isOptedOutOfTracking = !isDoNotSellOptOutState;

    // Opt-out or Opt-in of MParticle if the user completely opted out of tracking or if it blocked Braze specifically
    const optOutOfMParticle = mparticleConsentState === 0 || isOptedOutOfTracking;
    setOptOut(optOutOfMParticle);

    // Stop or resume Braze if the user completely opted out of tracking or if it blocked Braze specifically
    if (brazeConsentState === 0 || isOptedOutOfTracking) {
      ReactAppboy.stopWebTracking();
    } else {
      ReactAppboy.resumeWebTracking();
    }

    // Opt-out or Opt-in of Branch if the user completely opted out of tracking or if it blocked Branch specifically
    const optOutOfBranch = branchConsentState === 0 || isOptedOutOfTracking;
    window?.branch?.disableTracking(optOutOfBranch);
  }, [cookieComplianceConfig, otConsentGroups, servicesLoaded]);

  return <div data-testid="one-trust-cookie-banner" />;
};
